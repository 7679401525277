import React, {useEffect, useState} from 'react';

import A1Capital from './pages/a1capital';
import ISIKMENKUL from './pages/isikmenkul';
import OPTIMUS from './pages/optimus';
import OPTIMUS1 from './pages/optimus1';
import INFOYATIRIM from './pages/infoyatirim'
import ALNUSYATIRIM from './pages/alnusyatirim'
import TERAYATIRIM from './pages/terayatirim'
import OSMANLIYATIRIM from './pages/osmanliyatirim'
import PHILIPCAPITAL from './pages/philipcapital'
import ISTANBULPORTFOY from './pages/istanbulportfoy'
import INVESTAZ from './pages/investaz'
import STRATEJI from './pages/strateji'
import INTEGRAL from './pages/integral'
import NOORCM from './pages/noorCm'
import DINAMIK from './pages/dinamik'
import AHLATCI from './pages/ahlatci'
import ALB from './pages/alb'
import TFGISTANBUL from './pages/tfgIstanbul'
import MEKSA from './pages/meksa'
import GALATA from './pages/galata'
import IKON from './pages/ikonMenkul'
import ACAR from './pages/acarMenkul'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import HeaderSelector from './components/header'
import Footer from './components/footer'
import FloatingBar from './components/floatingBar'
import ProviderContext from './context/providerContext';
import GlobalStates from './context/globalStates';
import Register from './pages/register';
import NoMatch from './pages/nomatch';
import { CookiesProvider } from 'react-cookie';
import RedirectPage from "./pages/redirect";
import { customFetch } from './hooks/customFetch';
require('./assets/index')
const Index = (context) => {
  useEffect(() => {
    const query = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop) || ''
    });
    const promocode = query?.promocode;
    if(promocode) {
      sessionStorage.setItem('promocode', promocode);
    }
  }, []);

  if (process.env.REACT_APP_BUILDTYPE === "A1CAPITAL") {
    return <A1Capital {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ISIKMENKUL") {
    return <ISIKMENKUL {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "OPTIMUS") {
    return <OPTIMUS {...context} />
  }
  
  else if (process.env.REACT_APP_BUILDTYPE === "OPTIMUS1") {
    return <OPTIMUS1 {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "INFOYATIRIM") {
    return <INFOYATIRIM {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ISTANBULPORTFOY") {
    return <ISTANBULPORTFOY {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ALNUSYATIRIM") {
    return <ALNUSYATIRIM {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "TERAYATIRIM") {
    return <TERAYATIRIM {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "PHILIPCAPITAL") {
    return <PHILIPCAPITAL {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "OSMANLIYATIRIM") {
    return <OSMANLIYATIRIM {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "INVESTAZ") {
    return <INVESTAZ {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "STRATEJI") {
    return <STRATEJI {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "INTEGRALYATIRIM") {
    return <INTEGRAL {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "NOORCM") {
    return <NOORCM {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "DINAMIK") {
    return <DINAMIK {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "AHLATCI") {
    return <AHLATCI {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ALB") {
    return <ALB {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "TFGISTANBUL") {
    return <TFGISTANBUL {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "MEKSA") {
    return <MEKSA {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "GALATA") {
    return <GALATA {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "IKON") {
    return <IKON {...context} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ACAR") {
    return <ACAR {...context} />
  }

}
const App = (props) => {

  const [agreementsData, setAgreementsData] = useState(null);


  // useEffect(() => {
  //   const urlencoded = new URLSearchParams();
  //   urlencoded.append("StepCode", 'L');

  //   customFetch({
  //       params: `islem=registration_agreements`,
  //       method: 'POST',
  //       contentType: 'application/x-www-form-urlencoded',
  //       body: urlencoded,
  //       onSuccess: (res) => {
  //         if(res.errNo === 0) {
  //           setAgreementsData(res?.agreements)
  //         }
  //       }
  //   })
  // }, []);

  return (
    <CookiesProvider>
      <ProviderContext>
        <GlobalStates.Consumer>
          {context =>
            <Router>

              <HeaderSelector {...context}>
              <div className="body-class">
                <Switch>

                  <Route exact path="/">
                    <Index agreementsData={agreementsData}/>
                  </Route>
                  <Route exact path="/register">
                    <Register />
                  </Route>
                  <Route path="/redirect">
                    <RedirectPage />
                  </Route>
                  <Route path="*">
                    <NoMatch />
                  </Route>
                </Switch>
              </div>
              </HeaderSelector>

              <Footer />

              <FloatingBar />

            </Router>
          }
        </GlobalStates.Consumer>

      </ProviderContext>
    </CookiesProvider>

  )
}

export default App;
