

const params = "islem=image_show&image_column=ADRES_TEYIT&step=3"
const uploadParams = "islem=image_upload&image_column=ADRES_TEYIT&step=3&dosya_uzantisi="
const URL = "/webapi/Default.aspx?"

export async function getAdresImage(cookies) {
    let response = await fetch(URL + params, {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Authorization": cookies?.token
        },
    })
    response = await response?.json();
    return response
}


export const postAdresImage = (cookies, file, callback) => {
    let fileExtension = file.name.split('.').pop();
    fetch(`${URL}${uploadParams}${file?.type === "application/pdf" ? "pdf" : fileExtension}`, {
        method: "POST",
        headers: {
            "Content-Type": file.type,
            "Authorization": cookies?.token
        },
        body: file
    }).then((res) => {
        callback(res)
    }).catch(() => {
        console.error('Error uploading file!!!')
    })
}
