
import React from 'react'
import A1CapitalHeader from './headers/a1Header';
import IsikMenkulHeader from './headers/isikHeader';
import OptimusHeader from './headers/optimusHeader';
import Optimus1Header from './headers/optimus1Header';
import InfoYatirimHeader from './headers/infoHeader';
import IstanbulPortfoyHeader from './headers/istanbulPortfoyHeader';
import AlnusYatirimHeader from './headers/alnusHeader';
import TeraYatirimHeader from './headers/teraHeader';
import PhillipCapitalHeader from './headers/philipHeader';
import OsmanliHeader from './headers/osmanliHeader';
import InvestAzHeader from './headers/investazHeader';
import StratejiHeader from './headers/stratejiHeader';
import IntegralHeader from './headers/integralHeader';
import NoorCmHeader from './headers/noorCmHeader';
import DinamikHeader from './headers/dinamikHeader';
import AhlatciMenkulHeader from "./headers/ahlatciHeader";
import ALBHeader from "./headers/albHeader";
import TfgIstanbulHeader from "./headers/tfgIstanbulHeader";
import MeksaHeader from "./headers/meksaHeader";
import GalataHeader from "./headers/galataHeader";
import IkonHeader from "./headers/ikonHeader";
import AcarHeader from './headers/acarHeader';

const HeaderSelector = ( props ) => {

  if (process.env.REACT_APP_BUILDTYPE === "A1CAPITAL") {
    return <A1CapitalHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ISIKMENKUL") {
    return <IsikMenkulHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "OPTIMUS") {
    return <OptimusHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "OPTIMUS1") {
    return <Optimus1Header {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "INFOYATIRIM") {
    return <InfoYatirimHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ISTANBULPORTFOY") {
    return <IstanbulPortfoyHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ALNUSYATIRIM") {
    return <AlnusYatirimHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "TERAYATIRIM") {
    return <TeraYatirimHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "PHILIPCAPITAL") {
    return <PhillipCapitalHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "OSMANLIYATIRIM") {
    return <OsmanliHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "INVESTAZ") {
    return <InvestAzHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "STRATEJI") {
    return <StratejiHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "INTEGRALYATIRIM") {
    return <IntegralHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "NOORCM") {
    return <NoorCmHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "DINAMIK") {
    return <DinamikHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "AHLATCI") {
    return <AhlatciMenkulHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ALB") {
    return <ALBHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "TFGISTANBUL") {
    return <TfgIstanbulHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "MEKSA") {
    return <MeksaHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "GALATA") {
    return <GalataHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "IKON") {
    return <IkonHeader {...props} />
  }

  else if (process.env.REACT_APP_BUILDTYPE === "ACAR") {
    return <AcarHeader {...props} />
  }

}
export default HeaderSelector
