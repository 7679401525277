import React, { useState, useRef, Fragment, useEffect, useContext } from 'react'
import StepWizard from 'react-step-wizard';
import globalStates from '../../../../../context/globalStates';
import useRequest from '../../../../../hooks/useRequest';
const QuizStepUygunluk = require('../../../../../sources/Step1Q.json')
const QuizStepYerindelik = require('../../../../../sources/Step1QPortfoy.json')
const QuizStepYerindelikMeksa = require('../../../../../sources/Step1QPortfoyMeksa.json')
const SelectiveStepStandard = require('../../../../../sources/Step2Q.json')
const QuizStepUygunlukIntegral = require('../../../../../sources/Step1QIntegral.json')
const QuizStepYerindelikIntegral = require('../../../../../sources/Step1QPortfoyIntegral.json')
const SelectiveStepIntegral = require('../../../../../sources/Step2QIntegral.json')

const EpisodeOne = (props) => {
    const { innerTexts } = useContext(globalStates)
    const SelectiveStep = innerTexts.id === "INTEGRALYATIRIM" ? SelectiveStepIntegral : SelectiveStepStandard;
    const [answers, setAnswers] = useState([])
    const [danger, setDanger] = useState(false)
    const [dangerInput, setDangerInput] = useState(true)
    const [dangerText, setDangerText] = useState(false)
    const [notAllowed, setNotAllowed] = useState(false)
    const [meslekAnswer, setMeslekAnswer] = useState(null)
    const [yükümlülükAnswer, setYükümlülükAnswer] = useState(null)
    const [hasYükümlülük, setHasYükümlülük] = useState(false)
    const [selective1Point1, setSelective1Point1] = useState(0)
    const [selective2Point1, setSelective2Point1] = useState(0)
    const [selective3Point1, setSelective3Point1] = useState(0)
    const [selective4Point1, setSelective4Point1] = useState(0)
    const [selective5Point1, setSelective5Point1] = useState(0)
    const [sumOfSelective1, setsumOfSelective1] = useState(0)
    const [selective1Point2, setSelective1Point2] = useState(0)
    const [selective2Point2, setSelective2Point2] = useState(0)
    const [selective3Point2, setSelective3Point2] = useState(0)
    const [selective4Point2, setSelective4Point2] = useState(0)
    const [selective5Point2, setSelective5Point2] = useState(0)
    const [sumOfSelective2, setsumOfSelective2] = useState(0)
    const [selective1Point3, setSelective1Point3] = useState(0)
    const [selective2Point3, setSelective2Point3] = useState(0)
    const [selective3Point3, setSelective3Point3] = useState(0)
    const [selective4Point3, setSelective4Point3] = useState(0)
    const [selective5Point3, setSelective5Point3] = useState(0)
    const [sumOfSelective3, setsumOfSelective3] = useState(0)
    const [riskGetiri, setRiskGetiri] = useState()

    useEffect(() => {
      setTimeout(() => {
        setDanger(false)
      }, 3500);
    }, [danger])

    const alertStyle = {
        marginTop: "5%",
        padding:"2%",
        backgroundColor:"pink",
        borderRadius:"7px",
        opacity: 1,
        transition: "opacity 0.5s",
      };
      
      const hiddenAlertStyle = {
        marginTop: "5%",
        opacity: 0,
        transition: "opacity 0.5s",
        height: 0,
        overflow: "hidden",
        padding: 0,
      };
    

    const handleSelectivePoints = (state, val) => {
        const isRiskGetiriIncompatible = innerTexts.id === "ISTANBULPORTFOY" && (riskGetiri === 0 || riskGetiri === 1);
        if (isRiskGetiriIncompatible) {
            const message = "Tercih ettiğiniz risk profiliniz ve piyasalarda gerçekleştirdiğinizi belirttiğiniz işlem hacim ve sıklıkları arasında uyumsuzluk bulunmaktadır.\n\n"
            + "Bu nedenle, hangi ürün ya da hizmetlerin size uygun olduğunun tespit edilmesi mümkün olamamıştır.\n\n"
            + "Risk ve Getiri tercihini güncellemek için testin başlangıcına yönlendirileceksiniz.\n";
          alert(message);
          window.location.reload();
        } else {
            switch (state) {
                case selective4Point2:
                    setSelective4Point2(val)
                    break;
                case selective5Point2:
                    setSelective5Point2(val)
                    break;
                case selective4Point3:
                    setSelective4Point3(val)
                    break;
                case selective5Point3:
                    setSelective5Point3(val)
                    break;
            }
        }
    }


    useEffect(() => {
        setsumOfSelective1(selective1Point1 + selective2Point1 + selective3Point1 + selective4Point1 + selective5Point1)
    }, [selective1Point1, selective2Point1, selective3Point1, selective4Point1, selective5Point1])
    useEffect(() => {
        setsumOfSelective2(selective1Point2 + selective2Point2 + selective3Point2 + selective4Point2 + selective5Point2)
    }, [selective1Point2, selective2Point2, selective3Point2, selective4Point2, selective5Point2])
    useEffect(() => {
        setsumOfSelective3(selective1Point3 + selective2Point3 + selective3Point3 + selective4Point3 + selective5Point3)
    }, [selective1Point3, selective2Point3, selective3Point3, selective4Point3, selective5Point3])
    

    const { startFetch, response, loading } = useRequest()

    const [yerindelikTextAnswer, setYerindelikTextAnswer] = useState()

    const [activeClass] = useState(["card active-card", "card none"])
    const [btnActiveClass] = useState(["btn btn-secondary active-card", "btn none"])

    const [getFunctions, setGetFunctions] = useState({})
    const [activeStep, setActiveStep] = useState(1)

    const refInput = useRef([])
    const refSelectiveInput = useRef([])

    const [puan, setPuan] = useState(0)
    const [letter, setLetter] = useState("-")
const QuizStep =
  innerTexts.id === 'INTEGRALYATIRIM'
    ? innerTexts.yerindelik
      ? QuizStepYerindelikIntegral
      : QuizStepUygunlukIntegral
    : innerTexts.id === 'MEKSA'
    ? innerTexts.yerindelik
      ? QuizStepYerindelikMeksa
      : QuizStepUygunluk 
    : innerTexts.yerindelik
    ? QuizStepYerindelik
    : QuizStepUygunluk;

    useEffect(() => {
        if (response) {
            if (response.errNo === 0) {
                if (response.bilgiler) {
                    for (let index = 0; index < QuizStep.length; index++) {
                        const element = QuizStep[index];
                        for (var x in element) {
                            for (var y in response.bilgiler) {
                                if (element[x] === y) {
                                    for (let key = 0; key < element.options.length; key++) {
                                        const data = element.options[key];
                                        if (data.value === response.bilgiler[y]) {
                                            if (element.question.startsWith("Kredi borcu") || element.question.startsWith("Mesleğiniz")) {
                                                return;
                                            }
                                            if (element.question.startsWith("Risk")) {
                                                setRiskGetiri(Number(key))
                                            }
                                            handleAnswers(key, element, index);
                                        }
                                    }

                                }
                            }

                        }
                        setPuan(response.bilgiler.puan)
                        setLetter(response.bilgiler.harf)

                    }
                    for (let index = 0; index < SelectiveStep.length; index++) {
                        const element = SelectiveStep[index];
                        for (let fieldIndex = 0; fieldIndex < element.fields.length; fieldIndex++) {
                            const fieldElement = element.fields[fieldIndex];
                            for (var x in fieldElement) {
                                for (var y in response.bilgiler) {
                                    if (fieldElement[x] === y) {
                                        for (let key = 0; key < fieldElement.buttons.length; key++) {
                                            const data = fieldElement.buttons[key];
                                            if (data.value === response.bilgiler[y]) {
                                                handleSelectiveAnswers(element, index, fieldElement, fieldIndex, data, key)
                                            }
                                        }

                                    }
                                }
                            }

                        }
                    }
                }
                else {
                    if (activeStep === getFunctions.totalSteps) {
                        setDanger(false)
                        props.handleProgress(props.handleWizard.currentStep + 1)
                        props.handleWizard.nextStep()
                    } else {
                        setDanger(false)
                        getFunctions.nextStep()
                    }
                }
            }
        }
    }, [response])

    useEffect(() => {

        startFetch("islem=uygunluk_testi_getir&step=2", "GET")

    }, [activeStep === getFunctions.totalSteps])

    useEffect(() => {

        var errorElement = window.jQuery("html");
        var scrollPosition = errorElement.offset().top - 25;
        window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

    }, [activeStep])

    const Step1Q = (props) => {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div style={{ textAlign: "center", color: "#898989" }} className="card-body">
                                <h2>{innerTexts.label_step_one_episode_title}</h2>
                                <p>{innerTexts.label_step_one_episode_description}</p>
                                <p><strong>{innerTexts.label_step_one_episode_warning}</strong></p>
                                <button
                                    onClick={() => { props.goToStep(2); setGetFunctions(props) }}
                                    disabled={loading}
                                    style={{ width: "70%", filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                                    className="btn  btn-primary btn-lg"
                                >
                                    {loading ? "Yükleniyor..." : innerTexts.btn_text_step_one_episode}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const handleAnswers = async (key, item, i, x) => {
        x?.text === "Var" && x?.value <= 0 ? setDangerInput(true) : setDangerInput(false)

        refInput.current[i][key].className = activeClass[0]
        var model = {
            [item.field]: item.options[key].value,
            bolum: item.bolum,
            islem: item.islem,
            step: item.step,
            puan: item.options[key].puan,
        }
        if (x && x.value && !x.textInput && x.input) {
            model.mali_yukumluluk_tutar = x.value;
            setYükümlülükAnswer(x.value)
        }
        if (x && x.value && x.textInput) {
            setMeslekAnswer(x.value)
            model[item.field] = x.value;
        }
        answers[item.question] = model
        setAnswers(answers)
        for (let index = 0; index < item.options.length; index++) {
            if (index !== key) {
                try {
                    refInput.current[i][index].className = activeClass[1]
                } catch (error) {
                    console.log(index, key, i, refInput.current[i], item, "error")
                }

            }
        }
    }

    function removeNullParts(queryString) {
        const pairs = queryString.split('&');
        const filteredPairs = pairs.filter(pair => {
          const [key, value] = pair.split('=');
          return value !== 'null';
        });
        const newQueryString = filteredPairs.join('&');
        return newQueryString;
      }

    const assignSelectivePoints = (bolum, fieldIndex, pointOfQ, remove) => {
        if (remove) {
            bolum === 5 ? fieldIndex === 0 ? setSelective1Point1(0) : fieldIndex === 1 ? setSelective2Point1(0) : fieldIndex === 2 ? setSelective3Point1(0) : fieldIndex === 3 ? setSelective4Point1(0) : setSelective5Point1(0) :
            bolum === 6 ? fieldIndex === 0 ? setSelective1Point2(0) : fieldIndex === 1 ? setSelective2Point2(0) : fieldIndex === 2 ? setSelective3Point2(0) : fieldIndex === 3 ? setSelective4Point2(0) : setSelective5Point2(0) :
            fieldIndex === 0 ? setSelective1Point3(0) : fieldIndex === 1 ? setSelective2Point3(0) : fieldIndex === 2 ? setSelective3Point3(0) : fieldIndex === 3 ? setSelective4Point3(0) : setSelective5Point3(0)        
        } else {
            bolum === 5 ? fieldIndex === 0 ? setSelective1Point1(pointOfQ) : fieldIndex === 1 ? setSelective2Point1(pointOfQ) : fieldIndex === 2 ? setSelective3Point1(pointOfQ) : fieldIndex === 3 ? setSelective4Point1(pointOfQ) : setSelective5Point1(pointOfQ) :
            bolum === 6 ? fieldIndex === 0 ? setSelective1Point2(pointOfQ) : fieldIndex === 1 ? setSelective2Point2(pointOfQ) : fieldIndex === 2 ? setSelective3Point2(pointOfQ) : fieldIndex === 3 ? handleSelectivePoints(selective4Point2, pointOfQ) : handleSelectivePoints(selective5Point2, pointOfQ) :
            fieldIndex === 0 ? setSelective1Point3(pointOfQ) : fieldIndex === 1 ? setSelective2Point3(pointOfQ) : fieldIndex === 2 ? setSelective3Point3(pointOfQ) : fieldIndex === 3 ? handleSelectivePoints(selective4Point3, pointOfQ) : handleSelectivePoints(selective5Point3,pointOfQ)            
        }
    }

    const getTheSum = (q) => {
        if (q === 1) {
            return sumOfSelective1
        } else if (q === 2) {
            return sumOfSelective2
        } else if (q === 3) {
            return sumOfSelective3
        }
    }

    const handleSelectiveAnswers = (item, itemIndex, field, fieldIndex, btn, btnIndex) => {
        let isISY = innerTexts.id === "ISTANBULPORTFOY"
        let q1 = item.question === "Hangi yatırım araçlarında ne sıklıkta işlem yaptınız?"
        let q2 = item.question === "Bu yatırım araçlarında son bir yıla ait işlem hacminiz ne kadardır?"
        let isSelected = refSelectiveInput.current[itemIndex][fieldIndex][btnIndex].className === btnActiveClass[0]
        let remove;
        if (isISY && (q1 || q2) && isSelected) {
            refSelectiveInput.current[itemIndex][fieldIndex][btnIndex].className = btnActiveClass[1];
            var model = {
                [item.fields[fieldIndex].field]: null,
                bolum: item.bolum,
                islem: item.islem,
                step: item.step,
            }
            answers[item.question] = {
                ...answers[item.question],
                ...model
            }
            let pointOfQ = item.fields[fieldIndex].buttons[btnIndex].puan
            remove = true
            assignSelectivePoints(item.bolum, fieldIndex, pointOfQ, remove)
            setAnswers(answers)
            for (let index = 0; index < item.fields[fieldIndex].buttons.length; index++) {
                if (index !== btnIndex) {
                    refSelectiveInput.current[itemIndex][fieldIndex][index].className = btnActiveClass[1]
                }
            }
        } else {
            refSelectiveInput.current[itemIndex][fieldIndex][btnIndex].className = btnActiveClass[0];
            var model = {
                [item.fields[fieldIndex].field]: item.fields[fieldIndex].buttons[btnIndex].value,
                bolum: item.bolum,
                islem: item.islem,
                step: item.step,
            }
            let pointOfQ = item.fields[fieldIndex].buttons[btnIndex].puan
            remove = false
            assignSelectivePoints(item.bolum, fieldIndex, pointOfQ, remove)
            answers[item.question] = {
                ...answers[item.question],
                ...model
            }
            setAnswers(answers)
            for (let index = 0; index < item.fields[fieldIndex].buttons.length; index++) {
                if (index !== btnIndex) {
                    refSelectiveInput.current[itemIndex][fieldIndex][index].className = btnActiveClass[1]
                }
            }
        }
    }

    const SendAnswer = (item) => {
        
        var errorElement = window.jQuery("html");
        var scrollPosition = errorElement.offset().top - 25;
        window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

        if (answers[item.question]) {
            var model = answers[item.question]
            var params = ""
            for (var I in model) {
                params += '' + I + '=' + model[I] + '&'
            }
            if (params.includes("risk_ve_getiri_tercihi")) {
                startFetch("islem=uygunluk_testi_getir&step=2", "GET")
            }
            return startFetch(params, "POST")
        }
        setDanger(true)
    }

    const SendSelectiveAnswer = (item) => {    
        
        var errorElement = window.jQuery("html");
        var scrollPosition = errorElement.offset().top - 25;
        window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);
    
        if (innerTexts.id === "ISTANBULPORTFOY" && (riskGetiri === 0 || riskGetiri === 1)) {
            setTimeout(() => {
                alert("Tercih ettiğiniz risk profiliniz ve piyasalarda gerçekleştirdiğinizi belirttiğiniz işlem hacim ve sıklıkları arasında uyumsuzluk oluşmaması adına, 'Yüksek ve Çok Yüksek' riskli işlem seçimleri yapmamanız gerekmektedir!\n\n")                
            }, 200);
        }
        let isISY = innerTexts.id === "ISTANBULPORTFOY"
        let q0 = item.question === "Hangi yatırım araçları hakkında bilgi sahibisiniz?"
        let q1 = item.question === "Hangi yatırım araçlarında ne sıklıkta işlem yaptınız?"
        let q2 = item.question === "Bu yatırım araçlarında son bir yıla ait işlem hacminiz ne kadardır?"
        let q1text = "bolum=6&islem=uygunluk_testi&step=2&puan1=0"
        let q2text = "bolum=7&islem=uygunluk_testi&step=2&puan1=0"
        let q1orq2 = q1 ? q1text : q2text
        if (isISY && ( q1 || q2 )) {
            var model = answers[item.question]
            var params = ""
            for (var I in model) {
                params += '' + I + '=' + model[I] + '&'
            }
            params += `puan1=${getTheSum(q0 ? 1 : q1 ? 2 : 3)}`
            setDanger(false)
            let paramsToSend = removeNullParts(params)
            return startFetch(removeNullParts(params).length < 9 ? q1orq2 : paramsToSend , "POST")

        } else if (item.fields.length === ValidateSelective(item)) {
            var model = answers[item.question]
            var params = ""
            for (var I in model) {
                params += '' + I + '=' + model[I] + '&'
            }
            params += `puan1=${getTheSum(q0 ? 1 : q1 ? 2 : 3)}`
            setDanger(false)
            return startFetch(params, "POST")
        }

        setDanger(true)

    }
    const ValidateSelective = (item) => {
        var count = 0
        for (var x in answers[item.question]) {
            for (let index = 0; index < item.fields.length; index++) {
                const element = item.fields[index];
                if (element.field === x) {
                    count++
                }
            }
        }
        return count
    }
    const QuizStepRender = (item, index) => {

        var model = {};
        const renderOptions = (x, k) => {

            return (
                <Fragment key={k}>
                    <div key={k} className="col-md-12">
                        <div key={k} ref={r => {
                            model[k] = r
                            refInput.current[index] = model
                        }}
                            onClick={x.disabled ? null : x.textInput ?
                                () => { handleAnswers(k, item, index, { ...x, value: document.getElementById(`input_${k}`).value }) }
                                :
                                x.input ?
                                    () => { 
                                        setHasYükümlülük(x.text == "Var")
                                     handleAnswers(k, item, index, { ...x, value: document.getElementById(`input_${k}`).value }) }
                                    :
                                    () => { setHasYükümlülük(false); handleAnswers(k, item, index, x) }}
                             className="card" style={{ width: "100%" }}>
                            <div className="card-body">
                                <div style={{ display: "flex", justifyContent: "space-between" }} className="form-check">
                                    <label className="form-check-label" htmlFor={x.text}>
                                        {x.text}
                                    </label>
                                    {x.input && <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <input
                                            onChange={(e) => { 
                                                if (x.textInput) {
                                                    setMeslekAnswer(e.target.value)
                                                } else if (x.input) {
                                                    setYükümlülükAnswer(e.target.value)
                                                }
                                                handleAnswers(k, item, index, { ...x, value: document.getElementById(`input_${k}`).value }) }}
                                            type={x.textInput ? 'text' : 'number'}
                                            id={`input_${k}`}
                                            placeholder={x.textInput ? x.text : '0'}
                                            style={{ textAlign: "right", width: "30vw", minWidth: "200px" }} 
                                            value={x.textInput ? meslekAnswer : x.input ? yükümlülükAnswer : null}
                                            />

                                        {!x.textInput && <label htmlFor={`input_${k}`}>TL</label>}
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {x.textInput && dangerText ? <h5 style={{ color: "red", paddingRight: "1vw", textAlign: "right" }}>Zorunlu alanı doldurunuz!</h5>
                            :
                            x.input && dangerInput && <h5 style={{ color: "red", paddingRight: "2vw", textAlign: "right" }}>Geçerli bir rakam giriniz!</h5>}
                    </div>
                </Fragment>
            )
        }

        return (
            <div key={index} style={{ textAlign: "center" }}>
                <h2>{item.question}</h2>
                <div style={{ textAlign: "left" }} className="row">
                    {item.options.map(renderOptions)}
                    <div className="col-md-12">
                        <div style={danger ? alertStyle : hiddenAlertStyle}>
                            {innerTexts.error_messages_step_one_episode}
                        </div>
                    </div>
                    <div style={{ marginTop: "5%" }} className="row">
                        <div className="col-md-6">
                            <button
                                onClick={() => {
                                    setDanger(false)
                                    getFunctions.previousStep(); 
                                }}
                                className="btn  btn-secondr btn-lg btn-block"
                                disabled={loading}
                                style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                            >
                                {innerTexts.btn_text_back}
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button
                                onClick={() => { 
                                    if (item.field === "meslek_yerindelik" && (meslekAnswer === null || meslekAnswer === "")) {
                                        setDanger(true)
                                        return
                                    } else if (item.field === "mali_yukumluluk" && hasYükümlülük && (yükümlülükAnswer === null || yükümlülükAnswer === "" || yükümlülükAnswer < 1)) {
                                        setDanger(true)
                                        return
                                    }
                                    setDanger(false)
                                    SendAnswer(item);
                                 }}
                                disabled={loading}
                                style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                                className="btn  btn-primary btn-lg btn-block"
                            >
                                {loading ? "Yükleniyor..." : innerTexts.btn_text_forward}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const SelectiveStepRender = (item, itemIndex) => {
        var fieldModel = {}

        const renderFields = (fieldItem, fieldIndex) => {

            var model = {}
            return (
                <div key={fieldIndex} style={{ cursor: "pointer" }} className="card" style={{ width: "100%" }}>
                    <div className="card-body">
                        <div className="col-md-3 body">
                            <strong>{fieldItem.text + " "}</strong>
                            <br />
                            <span style={{ color: "#797979", fontSize: "smaller" }}>({fieldItem.description})</span>
                        </div>
                        {fieldItem.buttons.map((btn, btnIndex) => {
                            return (
                                <div key={btnIndex} className="col-md-3">
                                    <div style={{ border: "1px solid #4e3a3a80" }} key={fieldIndex} ref={r => {
                                        model[btnIndex] = r
                                        fieldModel[fieldIndex] = model
                                        refSelectiveInput.current[itemIndex] = fieldModel
                                    }} onClick={() => {
                                        handleSelectiveAnswers(item, itemIndex, fieldItem, fieldIndex, btn, btnIndex)
                                    }} className="btn">{btn.text}</div></div>

                            )
                        })}

                    </div>
                </div>
            )
        }

        return (
            <Fragment>
                <div key={itemIndex} style={{ textAlign: "center" }}>
                    <h2>{item.question}</h2>
                    <div style={{ textAlign: "left" }} className="row">
                        {
                            item.fields.map(renderFields)
                        }
                        <div style={{ marginTop: "5%" }} className="row">
                            <div className="col-md-6">
                                <button
                                    onClick={() => { getFunctions.previousStep(); }}
                                    className="btn  btn-secondr btn-lg btn-block"
                                    disabled={loading}
                                    style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                                >
                                    Geri
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button
                                    onClick={() => { SendSelectiveAnswer(item) }}
                                    className="btn  btn-primary btn-lg btn-block"
                                    disabled={loading}
                                    style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                                >
                                    İleri
                                </button>
                            </div>
                            <div className="col-md-12">
                                {
                                    danger ? <div style={{ marginTop: "5%" }} className="alert alert-danger" role="alert">
                                        {innerTexts.error_messages_step_one_episode}
                                    </div> : void 0
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
    const ResultRender = () => {


        return (
            <div key={""} style={{ textAlign: "center" }}>
                <h2></h2>
                <div style={{ textAlign: "left" }} className="row">
                    <div className="alert alert-info" role="alert">
                        <h2 className="alert-heading">Puan: <span style={{ color: "green" }}> {puan} </span></h2>
                        <h4>{innerTexts.label_step_one_episode_result_title}: {letter}</h4>
                        <hr />
                        <p className="mb-0">{innerTexts.label_step_one_episode_result_description}</p><br />
                        <table className="table table-Result table-bordered table-condensed">
                            <thead>
                                <tr>
                                    <th scope="col-6">Risk ve Getiri Grubu</th>
                                    <th scope="col-6">Açıklama</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">A</td>
                                    <td>Çok Düşük Riskli (Repo-Ters Repo, BPP, Yatırımcı bilgi formunda risk değeri 1 olan yatırım fonları vb.)</td>
                                </tr>
                                <tr>
                                    <td className="text-center">B</td>
                                    <td>Düşük Riskli (Hazine Bonosu, Devlet Tahvili, Hazine Kira Sertifikaları, Yatırımcı bilgi formunda risk değeri 2 ve 3 olan yatırım fonları  vb.)</td>
                                </tr>
                                <tr>
                                    <td className="text-center">C</td>
                                    <td>Orta Riskli (Hisse senedi, Hisse Senedi Fonları, Borsa Yatırım Fonları, Eurobond, Dövizli Tahviller, Özel Sektör Borçlanma Araçları, Kira Sertifikaları, Yabancı Menkul Kıymetler Fonu, Kıymetli Madenler Fonu, Kira ve Darphane Sertifikaları vb.)</td>
                                </tr>
                                <tr>
                                    <td className="text-center">D</td>
                                    <td>Yüksek Riskli (Türev İşlemler (Vadeli İşlem ve Opsiyon Piyasasında), Varant, Yatırım Kuruluşu Sertifikası, Yatırımcı bilgi formunda risk değeri 5 ve 6 olan yatırım fonları vb. vb.)</td>
                                </tr>
                                <tr>
                                    <td className="text-center">E</td>
                                    <td>Çok Yüksek Riskli (Tezgahüstü Türev İşlemler, Yapılandırılmış Borçlanma Araçları, Kaldıraçlı Alım Satım İşlemleri - FX, Yatırımcı bilgi formunda risk değeri 7 olan yatırım fonları  vb.)</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ marginTop: "5%" }} className="row">
                    <div className="col-md-6">
                        <button
                            onClick={() => { getFunctions.previousStep(); }}
                            className="btn  btn-secondr btn-lg btn-block"
                            disabled={loading}
                            style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                        >
                            {innerTexts.btn_text_back}
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button
                            onClick={() => {
                                setDanger(false)
                                props.handleWizard.nextStep()
                            }}
                            className="btn  btn-primary btn-lg btn-block"
                            disabled={loading}
                            style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                        >
                            {loading ? "Yükleniyor..." : innerTexts.btn_text_forward}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <StepWizard onStepChange={steps => { setActiveStep(steps.activeStep) }}>
            <Step1Q />
            {QuizStep.map(QuizStepRender)}
            {SelectiveStep.map(SelectiveStepRender)}
            <ResultRender />
        </StepWizard>
    )
}
export default EpisodeOne
