import CryptoJS from "crypto-js";

require("dotenv").config();
const secretKey = "asdjSU8*4.*sFGr";


export const getBrowserAlertText = () => {
    const browser = navigator.userAgent.toLowerCase();
    if (browser.indexOf('chrome') > -1) {
        return null;
    } else {
        return 'Google Chrome veya Microsoft Edge üzerinden yapılması durumunda sağlıklı çalışacaktır.';
    }
}



export const encryptData = (dataToEncrypt) => {
    const data = CryptoJS.AES.encrypt(
        dataToEncrypt,
        secretKey
    ).toString();

    return data
};

export const decryptData = (dataToDecrypt) => {
    const bytes = CryptoJS.AES.decrypt(dataToDecrypt, secretKey);
    const data = bytes.toString(CryptoJS.enc.Utf8);
    return data
};
