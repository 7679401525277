
import React, {Fragment, useContext, useEffect, useRef, useState} from 'react'
import {Cookies, useCookies} from 'react-cookie';
import globalStates from "../../../../../context/globalStates";
import GlobalStates from '../../../../../context/globalStates'
import useRequest from '../../../../../hooks/useRequest'
import CityRegions from '../../../../../sources/CityRegions.json'
import Modal from 'react-modal';
import AdressVerify from './adressVerify';
import {getAdresImage, postAdresImage} from "../../../../../services/getImage";
import ReactLoading from "react-loading";
import {customFetch} from "../../../../../hooks/customFetch";
import { decryptData } from '../../../../../helper/helper';

const customStyles = {
  content: {
    zIndex: 1000000,
    top: '50%',
    left: '50%',
    right: 'auto',
    position: "fixed",
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


const Episode = (props) => {
  const { innerTexts } = useContext(globalStates);
  const [cookies, setCookie] = useCookies(['token', "step", "TC", "CEP_NO"]);
  const [complateAdressVerify, setComplateAdressVerify] = useState(undefined)
  const [addressAlreadyVerified, setAddressAlreadyVerified] = useState(false)
  const [adressVerifyErrorMessage, setAdressVerifyErrorMessage] = useState("Adres Doğrulama Zorunludur")
  const [email, setEmail] = useState("")
  const [agreementHTML, setAgreementHTML] = useState("<!doctype html><html><head><title>Our Funky HTML Page</title><meta name=\"description\" content=\"Our first page\"><meta name=\"keywords\" content=\"html tutorial template\"></head><body>deneme template<br/><br/><p>AD:TEST</p><br/><p>SOYAD:TEST</p><br/><p>TC_NO:12345678901</p></body></html>")
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [isEmailAlreadyVerified, setIsEmailAlreadyVerified] = useState(false)
  const [verifyCode, setVerifyCode] = useState("")
  const [verifyErrorMessage, setVerifyErrorMessage] = useState("Email Doğrulama Zorunludur")
  const [progressVerify, setProgressVerify] = useState(false)
  const [country, setCountry] = useState("Türkiye")
  const [city, setCity] = useState("")
  const [cityKey, setCityKey] = useState(null)
  const [region, setRegion] = useState("")
  const [mahalle, setMahalle] = useState("")
  const [cadde, setCadde] = useState("")
  const [bina, setBina] = useState("")
  const [fileExtension, setFileExtension] = useState("")
  const [emailDuplicate, setEmailDuplicate] = useState(false)
  const [daire, setDaire] = useState("")
  const [address, setAddress] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [uploads, setUploads] = useState([])
  const [imageExists, setImageExists] = useState(false)
  const [counter, setCounter] = useState(false)
  const [sentForm, setSentForm] = useState(false)
  const [isContracts, setIsContracts] = useState(false)
  const [fileName, setFileName] = useState("");
  const [citiesRegions, setCitiesRegions] = useState(CityRegions)
  const [danger, setDanger] = useState(false)
  const { startFetch, fetchAdress, response, loading, ImageUpload } = useRequest()
  const { slugify } = useContext(GlobalStates);
  const hiddenFileInput = useRef([])

  useEffect(() => {
    if (danger) {
      var errorElement = window.jQuery("#alert");
      var scrollPosition = errorElement.offset().top - 25;
      window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);
    }
  }, [danger])
  

  const handleFileInput = (index) => {
    hiddenFileInput.current[index].click();
  }

  const fileUpload = (event, key) => {
    var files = event.target.files
    let file = files[0];
    if(file) {
     setFileName(file.name);
    }
    if (!innerTexts.uploadPDF) {
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        return alert("Dosya Formatı JPG veya PNG olmak zorundadır.");
      }
    }else{
      if (file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "application/pdf") {
        return alert("Dosya Formatı JPG, PNG veya PDF olmak zorundadır.");
      }

    }

    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = async function () {
      var imageURL = file.type === "application/pdf" ? "/images/nullpdf.jpg" : 'data:image/jpg;base64,' + window.btoa(reader.result);
      uploads[key] = imageURL
      setCounter(!counter)
      postAdresImage(cookies, file, () => {
        getImage();
      })
      reader.onerror = function () {
        console.log(reader.error);
      };

    }
  }

  const handleSelectCity = (e) => {

    if (e.target.selectedIndex === 0) {
      setCityKey(null)
      return
    }
    setCityKey(e.target.selectedIndex - 1);
    setRegion(CityRegions[e.target.selectedIndex - 1].ilceler[0].ilce_adi)
    setCity(e.target.value);
  }

  useEffect(() => {
    if (!innerTexts.verifyIdentity) {
      setIsContracts(true)
    }
  }, [loading])

  useEffect(() => {
    if (response) {
      if (response.errNo === -42 || response.errNo === -43 || response.errNo === -44) {
        if (response.errNo === -43) {
          setIsEmailVerified(true)
          setProgressVerify(false)
          setDanger(false)

        }
        if (response.errNo === -44) {
          setProgressVerify(true)
        }
        setVerifyErrorMessage(response.errDesc)
        return
      }
      if(response.errNo === -32){
        setDanger(true)
        setIsEmailVerified(false);
        setEmailDuplicate(true);
        setVerifyErrorMessage(response.errDesc)
        return
      }
      if(response.errNo === -49) {
        setDanger(true)
        setIsEmailVerified(false);
        setEmailDuplicate(true);
        setVerifyErrorMessage(response.errDesc)
        setProgressVerify(false)
        return
      }
      if (response.errNo === 0) {
        if (response.verifyCodeSent) {
          return
        }
        if (response.verifyCode === "True") {
          setIsEmailVerified(true)
          setProgressVerify(false)
          setDanger(false)
          setEmailDuplicate(false)
          return
        }
        setEmailDuplicate(false);
        if(sentForm){
          setSentForm(false);
          setComplateAdressVerify(true);
          setAddressAlreadyVerified(true);
          if(innerTexts.id === 'TERAYATIRIM' || innerTexts.id === 'DINAMIK') {
            props.goToStep(3)
          } else {
            props.nextStep();
          }
        }
      }
    }
  }, [response])

  const getImage = async () => {
    customFetch({
      params: "islem=image_show&image_column=ADRES_TEYIT&step=3",
      onSuccess: (res) => {
        if (res?.image) {
          uploads[res?.image_column] = res?.image?.split('.')[1] === "pdf" ? "/images/nullpdf.jpg" : `data:image/${res?.image?.split('.')[1] || 'jpg'};base64,` + res.file
          setUploads(uploads)
          setCounter(!counter)
          setImageExists(true)
          setFileName(res?.image)
          setFileExtension(res?.image?.split('.')[1])
          return
        }
        if (res?.bilgiler) {
          if (res.bilgiler.template) {
            setAgreementHTML(res.bilgiler.template)
            return
          }
          return
        }
      },
      onError: (err) => {
      }
    })
  }

  useEffect(() => {
    getImage()
  }, [])


  useEffect(() => {
      if(props.currentStep === 1) {
        customFetch({
          params: "islem=yatirimci_bilgileri_getir&step=3",
          method: 'GET',
          onSuccess: (response) => {
            if (response.bilgiler) {
              var data = response.bilgiler
              var citykeyTemp = CityRegions.findIndex(x => x.il_adi.toLowerCase() === data.il.toLowerCase())
              if (citykeyTemp === -1) {
                setCityKey(null)
              } else {
                setCityKey(citykeyTemp)
              }
              setIsEmailVerified(data.mail_verified === "True" ? true : false)
              setIsEmailAlreadyVerified(data.mail_verified === "True" ? true : false)
              setEmail(data.email)
              setCity(data.il)
              setRegion(data.ilce)
              setMahalle(data.mahalle)
              setCadde(data.cadde_sokak)
              setBina(data.dis_kapi)
              setDaire(data.ic_kapi)
              setAddress(data.adres)
              setComplateAdressVerify(data.adress_verified === "True" && data.adres ? true : false)
              setAddressAlreadyVerified(data.adress_verified === "True" && data.adres ? true : false)
              setDanger(false);
              fetchAdress(`/il/${decryptData(cookies?.TC)}`, 'GET')
            }
          },
          onError: (err) => {
            fetchAdress(`/il/${decryptData(cookies?.TC)}`, 'GET')
          }
        } )

      }
  }, [props.currentStep])

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  const sendEmailVerify = () => {
    var params = "";
    // if (!isEmailVerified) {
      if (validateEmail()) {
        const model = {
          islem: 'email_dogrulama',
          tc_no: decryptData(cookies?.TC),
          dogrulanacak_email: email
        }
        for (var I in model) {
          params += '' + I + '=' + model[I] + '&'
        }
        startFetch(params, "POST")
        setProgressVerify(true)
      }
      else {
        setDanger(true)
      }
    // }
  }

  const tryVerify = () => {
    let params = ""
    const model = {
      islem: "email_dogrulama_sonuc",
      dogrulanacak_mail: email,
      verify_code: verifyCode,
      tc_no: decryptData(cookies?.TC),
    }
    for (var I in model) {
      params += '' + I + '=' + model[I] + '&'
    }
    startFetch(params, "POST", () => {
      const promoCode = sessionStorage.getItem('promocode')
        if (promoCode) {
          let promoParams = [];
          const promoModel = {
            Firstname: sessionStorage.getItem("first_name"),
            Lastname: sessionStorage.getItem("last_name"),
            Email: email,
            PhoneNumber: decryptData(cookies?.CEP_NO),
            PromoCode: promoCode,
          }
          for (var J in promoModel) {
            const encodedKey = encodeURIComponent(J);
            const encodedValue = encodeURIComponent(promoModel[J]);
            promoParams.push(encodedKey + "=" + encodedValue);
          }
          promoParams = promoParams.join("&");
          customFetch({
            params: 'islem=post_promode',
            contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
            body: promoParams,
            method: 'POST'
          })
        }
    })
  }
  const saveForm = (next) => {
    
    var errorElement = window.jQuery("html");
    var scrollPosition = errorElement.offset().top - 25;
    window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

    if (validateEmail()) {

      if (innerTexts.addresVerify && !complateAdressVerify) {
        return setDanger(true)
      }
      if (innerTexts.emailVerify && !isEmailVerified) {
        setIsEmailVerified(false);
        return setDanger(true)
      }

      if(innerTexts.addresVerify && addressAlreadyVerified && (innerTexts.id === 'DINAMIK' || innerTexts.id === 'TERAYATIRIM')) {
        props.nextStep();
        return setDanger(false)
      }

      if(innerTexts.addresVerify && (city !== "" && region !== "" && address !== "" && mahalle !== "" && cadde !== "" && bina !== "" && daire !== "")){
        var params = ""
        var model = {
          islem: "iletisim_bilgileri",
          step: "3",
          email: email,
          ulke: country,
          il: city,
          ilce: region,
          mahalle: mahalle,
          cadde_sokak: cadde,
          dis_kapi: bina,
          ic_kapi: daire,
          adres: address.toUpperCase(),
        }

        if(postalCode !== "") {
          model.posta_kodu = postalCode
        }

        for (var I in model) {
          params += '' + I + '=' + model[I] + '&'
        }

        startFetch(params, "POST", null, null, (res) => {
          if(next === "next" && res.errNo === 0) {
              setComplateAdressVerify(true);
              props.goToStep(2);


            // if(innerTexts.id === 'TERAYATIRIM') {
            //   setComplateAdressVerify(true);
            //   props.goToStep(3)
            // } else {
            //   setComplateAdressVerify(true);
            //   if((
            //       innerTexts.id === 'DINAMIK' ||
            //       innerTexts.id === 'MEKSA' ||
            //       innerTexts.id === 'A1CAPITAL' ||
            //       innerTexts.id === 'INFOYATIRIM' ||
            //       innerTexts.id === 'ALNUSYATIRIM' ||
            //       innerTexts.id === 'GALATA' ||
            //       innerTexts.id === 'ALB' ||
            //       innerTexts.id === 'ISIKMENKUL'
            //     )
            //      && innerTexts.id !== 'TERAYATIRIM') {
            //     props.goToStep(2);
            //   } else {
            //     props.nextStep();
            //   }
            // }
          }
        })
        return
      }

      if (city !== "" && region !== "" && address !== "" && (innerTexts.addresVerify || imageExists || uploads["ADRES_TEYIT"])) {
        var params = ""
        var model = {
          islem: "iletisim_bilgileri",
          step: "3",
          email: email,
          ulke: country,
          il: city,
          ilce: region,
          mahalle: mahalle,
          cadde_sokak: cadde,
          dis_kapi: bina,
          ic_kapi: daire,
          adres: address.toUpperCase(),
        }
        if(postalCode !== "") {
          model.posta_kodu = postalCode
        }
        for (var I in model) {
          params += '' + I + '=' + model[I] + '&'
        }
        setDanger(false)
        if(!innerTexts.addresVerify) {
          setSentForm(true)
        }

        startFetch(params, "POST", null, null, (res) => {
          if(res.errNo === 0) {
            if(innerTexts.id === 'TERAYATIRIM' || innerTexts.id === 'DINAMIK') {
              setComplateAdressVerify(true);
              props.goToStep(3)
            } else {
              setComplateAdressVerify(true);
              props.goToStep(2);
            }
          }
        })

        return
      }
      return setDanger(true)
    }
    return setDanger(true)
  }

  // useEffect(() => {
  //   if(complateAdressVerify){
  //     alert("useeffect if complateAdressVerify")
  //     saveForm()
  //   }
  // }, [complateAdressVerify])

  if (props.currentStep !== 1) {
    return (<div></div>)
  }

  const renderAdresTeyitImage = (fName, requestedPDF) => {
    const ext = fName.split('.')[1];
    if(ext === 'pdf') {
      if(requestedPDF) {
        return `/images_test1/${fName}`
      }
      return '/images/nullpdf.jpg'
    } else {
      return `/images_test1/${fName}`
    }
  }

  function turkcesiralama(a, b, choice){
    var atitle = choice === "ilce" ? a.ilce_adi : a.il_adi;
    var btitle = choice === "ilce" ? b.ilce_adi : b.il_adi;
    var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (atitle.length === 0 || btitle.length === 0) {
      return atitle.length - btitle.length;
    }
    for(var i=0;i<atitle.length && i<btitle.length;i++){
      var ai = alfabe.indexOf(atitle[i]);
      var bi = alfabe.indexOf(btitle[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }



  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-24">
            <div className="alert alert-warning" role="alert" style={{ textAlign: 'center', fontWeight: "bold" }}>
              Bu alana e-Devlet veri tabanında kayıtlı yerleşim yeri adresinizi eklemeniz gerekmektedir.<br/>
              Bu adres, tebligat adresi olarak tanımlanacak olup, MKK dahil tüm bildirimler ve tebligatlar bu adres üzerinden yapılacaktır.
            </div>
          </div>
          <div className="col-md-24">
            {danger ? (
                <div
                    className="alert alert-danger"
                    role="alert"
                    id='alert'
                >
                  {`${innerTexts.error_messages_step_one_episode}. ${innerTexts.addresVerify && adressVerifyErrorMessage || ""}. ${innerTexts.emailVerify && verifyErrorMessage}`}
                  {danger && emailDuplicate ? verifyErrorMessage : ""}
                </div>
            ) : (
                void 0
            )}
          </div>
        </div>
        <div className="row">
          <div className={"col-md-10 col-lg-10"}>
            <div
              style={{ display: "block", zIndex: 2 }}
              className="input-group  input-group-lg lg-12"
            >
              <div className="input-group-prepend">
                <label className="control-label">
                  {innerTexts.label_email}
                </label>
              </div>
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                style={{ border: danger && !isEmailVerified ?  '1px solid #eb8787': '', backgroundColor: danger && !isEmailVerified ? '#FFF8F8' : '' }}
                defaultValue={email}
                type="text"
                required="required"
                className="form-control"
                placeholder=""
              />
            </div>
          </div>
          {innerTexts.emailVerify && <div className="col-md-2 col-lg-2">
            <div
              style={{ display: "block", zIndex: 999998 }}
              className="input-group  input-group-lg lg-12"
            >
              <div className="input-group-prepend">
                <label className="control-label">
                  Doğrulama
                </label>
              </div>
              <button style={{ zIndex: 999999 }} disabled={isEmailVerified || loading} onClick={sendEmailVerify} className={isEmailVerified ? "btn btn-success btn-lg btn-block" : "btn btn-primary btn-lg btn-block"}>{loading ? <ReactLoading className="EmailLoading" type={"spin"} color={"#fff"} height={'10%'} width={'12%'}/> : (isEmailVerified ? "Doğrulandı" : "Kod Gönder")}</button>
            </div>
          </div>}
          <div className="row">
            {progressVerify && <div className="col-md-8 col-lg-8 col-md-push-2" style={{ marginLeft: 30, marginRight: 30, marginTop: 15, backgroundColor: "#fcf8e3", padding: "20px", zIndex: 9999 }}>
              <h4 id="dogrulama">E-mail adresinize gelen doğrulama kodunu giriniz.</h4>
              <div className="form-group">
                <label>{innerTexts.label_verify}:</label>
                <input
                    value={verifyCode}
                    onChange={(e) => {
                      setVerifyCode(e.target.value);
                    }}
                    style={{textAlign: "center"}}
                    type="tel"
                    id="verify"
                    maxLength="6"
                    placeholder="_ _ _ _ _ _"
                    className="form-control"
                    required
                />
                <h5 style={{color: 'red'}}>{`${verifyErrorMessage}`}</h5>
              </div>
              <button
                  onClick={(e) => {
                    tryVerify(e);
                  }}
                  type="submit"
                  className="form_button btn btn-default btn-primary btn-block"
                  id="form-submit"
              >
                Doğrula
              </button>
            </div>}
          </div>
          {innerTexts.addresVerify ?
            <AdressVerify
                complateAddressVerify={complateAdressVerify}
                addressAlreadyVerified={addressAlreadyVerified}
                setMahalle={setMahalle}
                setCadde={setCadde}
                setBina={setBina}
                setDaire={setDaire}
                setCity={setCity}
                setRegion={setRegion}
                setPostalCode={setPostalCode}
                bilgiler={{
                  mahalle: mahalle,
                  cadde: cadde,
                  bina: bina,
                  daire: daire,
                  il: city,
                  ilce: region,
                }}
                address={address}
                setAddress={setAddress}
                setVerify={setComplateAdressVerify}
                errorMessage={setAdressVerifyErrorMessage}
            />
            : <>
                <div className="col-md-6">
                  <div
                      style={{ display: "block" }}
                      className="input-group  input-group-lg lg-12"
                  >
                    <div className="input-group-prepend">
                      <label className="control-label">{innerTexts.label_country}</label>
                    </div>
                    <select
                        defaultValue={country}
                        disabled
                        className="form-control form-control-lg"
                    >
                      <option value="Türkiye">Türkiye</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                      style={{ display: "block", zIndex: 1 }}
                      className="input-group  input-group-lg lg-12"
                  >
                    <div className="input-group-prepend">
                      <label className="control-label">{innerTexts.label_region}</label>
                    </div>
                    <select
                        onChange={handleSelectCity}
                        value={city}
                        className="form-control form-control-lg"
                    >
                      <option value={null}>{innerTexts.label_pick_city}</option>
                      {citiesRegions
                          .sort(turkcesiralama)
                          .map((x, i) => {
                        return (
                            <option key={i} value={x.il_adi.toLocaleLowerCase('tr')}>
                              {x.il_adi}
                            </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                      style={{ display: "block" }}
                      className="input-group  input-group-lg lg-12"
                  >
                    <div className="input-group-prepend">
                      <label className="control-label"> {innerTexts.label_recidency} </label>
                    </div>
                    <textarea
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        value={address}
                        style={{ height: 150, overflow: "hidden", resize: "none", textDecoration: "uppercase" }}
                        aria-describedby="inputGroup-sizing-lg"
                        maxLength="100"
                        type="text"
                        required="required"
                        className="form-control"
                        placeholder="Adres"
                    />
                  </div>

                </div>

                <div className="col-md-6" style={{ marginBottom: 30 }}>
                  <div
                      style={{ display: "block", zIndex: 1 }}
                      className="input-group  input-group-lg lg-12">
                    <div className="input-group-prepend">
                      <label className="control-label">{innerTexts.label_district}</label>
                    </div>

                    <select
                        onChange={(e) => {
                          setRegion(e.target.value);
                        }}
                        disabled={cityKey === null ? true : false}
                        value={region.toLowerCase()}
                        className="form-control form-control-lg"
                    >
                      {cityKey === null ? (
                          <option>{innerTexts.label_pick_district}</option>
                      ) : (
                          citiesRegions[cityKey].ilceler
                              .sort((a,b) => turkcesiralama(a,b,"ilce"))
                              .map((x, i) => {
                                return (
                                    <option
                                        key={i}
                                        value={slugify(x.ilce_adi.toLocaleLowerCase('tr'))}
                                    >
                                      {x.ilce_adi}
                                    </option>
                                );
                              })
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-md-6" style={{ marginTop: '20px' }} >
                  <div className="card" style={{ border: danger && imageExists ?  '1px solid #eb8787': '', backgroundColor: danger && !isEmailVerified ? '#FFF8F8' : '' }}  >
                    <div style={{ textAlign: "center", marginTop: 30 }}>
                      <div className="card-body">
                        {fileName && <img style={{ width: "100px", height: "100px" }} src={uploads['ADRES_TEYIT']} className="card-img-top" alt="asd" />}
                        {fileName && <a download={`AdresTeyit.${fileExtension}`} href={uploads['ADRES_TEYIT'] ? uploads['ADRES_TEYIT'] : '#'} target='_blank' rel='noopener noreferrer'>
                          <p style={{ color: '#046d88', fontWeight: 600, cursor: 'pointer' }}>{fileName}</p>
                          <p style={{ color: '#046d88', fontWeight: 600, cursor: 'pointer' }}>{ fileName ? 'Yüklediğiniz belgeyi görüntülemek için tıklayınız.' : ""}</p>
                        </a>}
                        <p>Adres teyit belgenizi yükleyiniz.</p>
                        <p><span style={{ color: "#949494" }}>{innerTexts.label_step_four_upload_text}</span></p>
                        {loading ?
                            <button className="btn btn-primary btn-block">Bir fotoğraf yükleniyor. Bekleyiniz...</button>
                            : <button onClick={() => { handleFileInput(0) }} className="btn btn-primary btn-block">Dosya Yükle veya Belge Seç</button>
                        }

                        <input
                            type="file"
                            ref={r => hiddenFileInput.current[0] = r}
                            onChange={(e) => { fileUpload(e, "ADRES_TEYIT") }}
                            style={{ display: 'none' }} />
                      </div>
                    </div>

                  </div>
                </div>
                  {
                    danger && !imageExists ? (
                      <div className="col-md-6">
                        <div
                            className="alert alert-danger"
                            role="alert"
                        >
                          Belgenizi yükleyiniz!
                        </div>
                      </div>
                    ) : (
                      void 0
                    )
                  }
              </>}



          <div style={{ marginTop: 20 }} className="col-md-6">
            <button
                disabled={loading}
                style={{ filter: loading ? "brightness(130%)" : "", cursor: loading ? "no-drop" : "pointer" }}
              onClick={() => {
                props.handleWizard.previousStep();
              }}
              className="btn btn-secondr btn-lg btn-block"
            >
              {innerTexts.btn_text_back}
            </button>
          </div>
          <div style={{ marginTop: 20 }} className="col-md-6">
            <button
                disabled={loading}
                style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
              onClick={() => {
                if((complateAdressVerify || addressAlreadyVerified) && innerTexts.addresVerify) {
                  if(!emailDuplicate && (isEmailAlreadyVerified || isEmailVerified)) {
                    if(innerTexts.id === 'TERAYATIRIM' || innerTexts.id === 'DINAMIK') {
                      return props.goToStep(3)
                    } else {
                      return props.nextStep();
                    }

                  }
                  else {
                    return saveForm("next");
                  }
                } else if(!innerTexts.addresVerify) {
                  return saveForm();
                } else {
                  setDanger(true);
                }
              }}
              className="btn  btn-primary btn-lg btn-block"
            >
              {loading ? "Yükleniyor..." : (!isEmailVerified ? 'Onayla': innerTexts.btn_text_forward)}
            </button>
          </div>

        </div>
      </div>

    </Fragment>
  );
}
export default Episode
