import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import globalStates from '../../../../../context/globalStates';
import useRequest, {URL} from '../../../../../hooks/useRequest'
import List from "../../../../../sources/variables/companies.json";
import {useCookies} from 'react-cookie';
import {getMasak, getMasakGetir} from "../../../../../services/getMasak";
import {getTemplate} from "../../../../../services/getTemplate";
import ReactLoading from "react-loading";
import {customFetch} from "../../../../../hooks/customFetch";

const EpisodeMasak = (props) => {
    const [accountOpeningPorpuse, setAccountOpeningPorpuse] = useState("");
    const [masakForms, setMasakForms] = useState({
        masakAmac: null,
        masakTalep: null,
        masakHacim: null,
        masakSiklik: null,
        mal: null,
        gelir: null,
        talep: null,
    });
    const [formLoading, setFormLoading] = useState(false);
    const [monthlyIncome, setMonthlyIncome] = useState("")
    const [fortune, setFortune] = useState("");
    const [estimatedTradingVolume, setEstimatedTradingVolume] = useState("");
    const [estimatedTransactionFrequency, setEstimatedTransactionFrequency] = useState("");
    const [cookies] = useCookies();


    const [danger, setDanger] = useState({valid: false, message: ""});
    const {innerTexts} = useContext(globalStates);

    const masakGetir = () => {
        setFormLoading(true);
        getMasak("AMAC", cookies, (res1) => {
            getMasak("MAL", cookies, (res2) => {
                getMasak("GELIR", cookies, (res3) => {
                    getMasak("URUN", cookies, (res4) => {
                        getMasak("SIKLIK", cookies, (res5) => {
                            getMasak("HACIM", cookies, (res6) => {
                                getMasakGetir(cookies, (response) => {
                                        if (response && response.bilgiler) {
                                            setMasakForms({
                                                masakAmac: res1.masak,
                                                masakTalep: res4.masak?.map(item => {
                                                    const talepler = response?.bilgiler?.talep.split(',');
                                                    return {
                                                        ...item,
                                                        value: item.value.toString(),
                                                        checked: !!talepler.includes(item.key)
                                                    }
                                                }),
                                                masakHacim: res6.masak,
                                                masakSiklik: res5.masak,
                                                mal: res2.masak,
                                                gelir: res3.masak,
                                                talep: response?.bilgiler?.talep,
                                            })
                                            setAccountOpeningPorpuse(response?.bilgiler?.amac)
                                            setEstimatedTransactionFrequency(response?.bilgiler?.siklik)
                                            setEstimatedTradingVolume(response?.bilgiler?.hacim)
                                            setMonthlyIncome(response?.bilgiler?.gelir)
                                            setFortune(response?.bilgiler?.mal)
                                            setFormLoading(false)
                                        }
                                    }
                                )
                            })
                        })
                    })
                })
            })
        })
    }

    useEffect(() => {
        masakGetir()
    }, [])




    const handleCheckboxes = (id) => {
        let tempTalep = masakForms?.masakTalep;
        let temp = tempTalep.map(x => {
            if(x.key == id) {
                setDanger({...danger, checkboxError: false})
                return {...x, checked: !x.checked};
            } else {
                return x;
            }
        });
        setMasakForms({...masakForms, masakTalep: temp })
    }

    const saveForm = () => {
        
        var errorElement = window.jQuery("html");
        var scrollPosition = errorElement.offset().top - 25;
        window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

        const checked = masakForms?.masakTalep?.filter(x => x.checked === true);
        if (
            accountOpeningPorpuse &&
            estimatedTradingVolume &&
            estimatedTransactionFrequency &&
            fortune &&
            monthlyIncome
        ) {
            if (innerTexts.id === "OSMANLIYATIRIM" && masakForms?.masakTalep?.length > 0 && checked.length < 1) {
                return setDanger({message: innerTexts.error_messages_masak_step, valid: true, checkboxError: true})
            }
            let params = "";
            let talepString = masakForms?.masakTalep?.filter(x => x.checked).map(item => item.key).join(",")
            params = `islem=masak_kaydet&amac=${accountOpeningPorpuse?.trim()}&hacim=${estimatedTradingVolume?.trim()}&siklik=${estimatedTransactionFrequency?.trim()}&${talepString ? "talep=" + talepString?.trim() : ""}&mal=${fortune?.trim()}&gelir=${monthlyIncome?.trim()}`
            customFetch({
                params: params,
                method: "POST",
                onSuccess: (response) => {
                    setDanger({ valid: false, message: '', checkboxError: false })
                    setFormLoading(false);

                    //step3.js
                    // Belirtilen sayfada EpisodeFour Osmanlıdan kaldırıldı, ancak bu durumda step ilerlemesi yapılamadığı için ilerleme fonksiyonu Osmanlı'ya özel olarak değiştirildi
                    innerTexts.id === "OSMANLIYATIRIM" ? props.handleWizard.nextStep() : props.nextStep();

                },
                onError: (error) => {
                    setFormLoading(false);
                    setDanger({message: error.errDesc, valid: true})
                }
            })
        } else {
            return setDanger({message: innerTexts.error_messages_masak_step, valid: true})
        }

        return
    }

        return (
            <Fragment>
                {!formLoading ?
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    danger.valid
                                        ?
                                        <div className="alert alert-danger" role="alert">
                                            Lütfen belirtilen alanları eksiksiz ve doğru bir şekilde seçiniz.
                                        </div>
                                        :
                                        void 0
                                }

                            </div>
                            {masakForms?.gelir && innerTexts.id !== 'MEKSA' && 
                                <div className="col-12 col-md-4">
                                    <div style={{display: "block", zIndex: 2}}
                                         className="input-group  input-group-lg lg-12">
                                        <div className="input-group-prepend">
                                            <label className="control-label">Aylık Geliriniz</label>
                                        </div>
                                        <select style={{ border: danger.valid && !monthlyIncome ? '1px solid #eb8787': '', backgroundColor: danger.valid && !monthlyIncome ? '#FFF8F8' : '' }} onChange={(e) => {
                                            setMonthlyIncome(e.target.value || "")
                                        }} defaultValue={monthlyIncome} className="form-control form-control-lg">
                                            <option value={""}>Seçiniz</option>
                                            {masakForms?.gelir.map((item) => {
                                                return (
                                                    <option key={item.key} value={item.key}>{item.value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            }
                            {masakForms?.mal && <div className="col-12 col-md-4">
                                <div style={{display: "block"}} className="input-group  input-group-lg lg-12">
                                    <div className="input-group-prepend">
                                        <label className="control-label">{innerTexts.id === 'ISTANBULPORTFOY' ? 'Kurumumuz Bünyesinde Yatırım Yapmayı Düşündüğünüz Tasarruf Miktarı' : 'Toplam Mal Varlığınız'}</label>
                                    </div>
                                    <select style={{ border: danger.valid && !fortune ? '1px solid #eb8787': '', backgroundColor: danger.valid && !fortune ? '#FFF8F8' : '' }} onChange={(e) => {
                                        setFortune(e.target.value)
                                    }} defaultValue={fortune} className="form-control form-control-lg">
                                        <option value="">Seçiniz</option>
                                        {masakForms?.mal.map((item) => {
                                            return (
                                                <option key={item.key} value={item.key}>{item.value}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>}
                        </div>
                        {innerTexts.id !== 'MEKSA' && <div className="row">

                            <div className="col-12 col-md-4">
                                <div style={{display: "block", zIndex: 1}}
                                     className="input-group  input-group-lg lg-12">
                                    <div className="input-group-prepend">
                                        <label className="control-label">Getiri Beklentisi</label>
                                    </div>
                                    {masakForms?.masakAmac && <select style={{ border: danger.valid && !accountOpeningPorpuse ? '1px solid #eb8787': '', backgroundColor: danger.valid && !accountOpeningPorpuse ? '#FFF8F8' : '' }} onChange={(e) => {
                                        setAccountOpeningPorpuse(e.target.value)
                                    }} defaultValue={accountOpeningPorpuse} className="form-control form-control-lg">
                                        <option value="">Seçiniz</option>
                                        {masakForms?.masakAmac.map((item) => {
                                            return (
                                                <option key={item.key} value={item.key}>{item.value}</option>
                                            )
                                        })}

                                    </select>}
                                </div>
                            </div>

                            <div className="col-12 col-md-4">
                                <div style={{display: "block", zIndex: 1}}
                                     className="input-group  input-group-lg lg-12">
                                    <div className="input-group-prepend">
                                        <label className="control-label">Tahmini İşlem Hacmi (Aylık)</label>
                                    </div>
                                    <select style={{ border: danger.valid && !estimatedTradingVolume ? '1px solid #eb8787': '', backgroundColor: danger.valid && !estimatedTradingVolume ? '#FFF8F8' : '' }} onChange={(e) => {
                                        setEstimatedTradingVolume(e.target.value)
                                    }} defaultValue={estimatedTradingVolume} className="form-control form-control-lg">
                                        <option value="">Seçiniz</option>
                                        {masakForms?.masakHacim && masakForms?.masakHacim.map((item) => {
                                            return (
                                                <option key={item.key} value={item.key}>{item.value}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-md-4">
                                <div style={{display: "block", zIndex: 1}} className="input-group input-group-lg lg-12">
                                    <div className="input-group-prepend">
                                        <label className="control-label">Tahmini İşlem Sıklığı (Aylık)</label>
                                    </div>
                                    <select style={{ border: danger.valid && !estimatedTransactionFrequency ? '1px solid #eb8787': '', backgroundColor: danger.valid && !estimatedTransactionFrequency ? '#FFF8F8' : '' }} onChange={(e) => {
                                        setEstimatedTransactionFrequency(e.target.value)
                                    }} defaultValue={estimatedTransactionFrequency}
                                            className="form-control form-control-lg">
                                        <option value={""}>Seçiniz</option>
                                        {masakForms?.masakSiklik && masakForms?.masakSiklik.map((item) => {
                                            return (
                                                <option key={item.key} value={item.key}>{item.value}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>}
                        {masakForms?.masakTalep && innerTexts?.id === 'OSMANLIYATIRIM' &&
                            <div className="row" style={{marginTop: 20}}>
                                <div className="col-36 col-md-12">
                                    <div style={{display: "block", zIndex: 1}}
                                         className="input-group input-group-lg lg-24">
                                        <div className="input-group-prepend">
                                            <label className="control-label">Talep Edilen Ürünler</label>
                                        </div>
                                        <div className="form-check form-control form-control-lg" style={{ border: danger.valid && danger.checkboxError ? '1px solid #eb8787': '', backgroundColor: danger.valid && danger.checkboxError ? '#FFF8F8' : '' }}>
                                            {masakForms?.masakTalep && masakForms?.masakTalep?.map((item) => {
                                                return (
                                                    <div style={{marginTop: 5}}>
                                                        <input onChange={() => {
                                                            handleCheckboxes(item.key)
                                                        }} type="checkbox" checked={item.checked} className="form-check-input" value={item.key}
                                                               id={item.value.trim()}/>
                                                        <label className="form-check-label" style={{
                                                            color: "black",
                                                            fontSize: 14,
                                                            fontWeight: "400",
                                                            marginLeft: 10
                                                        }} htmlFor={item.value.trim()}>{item.value}</label>
                                                    </div>
                                                );
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>}

                        <div style={{marginTop: 20}} className="col-12 col-md-6">
                            <button
                                onClick={() => {
                                    props.previousStep()
                                }}
                                className="btn  btn-secondr btn-lg btn-block"
                                disabled={formLoading}
                                style={{
                                    filter: formLoading ? "brightness(120%)" : "",
                                    cursor: formLoading ? "no-drop" : "pointer"
                                }}
                            >
                                Geri
                            </button>
                        </div>
                        <div style={{marginTop: 20}} className="col-12 col-md-6">
                            <button
                                onClick={saveForm}
                                className="btn  btn-primary btn-lg btn-block"
                                disabled={formLoading}
                                style={{
                                    filter: formLoading ? "brightness(120%)" : "",
                                    cursor: formLoading ? "no-drop" : "pointer"
                                }}
                            >
                                {formLoading ? 'Yükleniyor' : 'İleri'}
                            </button>
                        </div>

                    </div>
                    :
                    <>
                        <div className="KPSLoadingContainer">
                            <ReactLoading className="KPSLoading" type={"spin"} color={"#000"} height={'10%'}
                                          width={'5%'} />
                            <div style={{textAlign: "center"}}>Yükleniyor...</div>
                        </div>
                    </>
                }
            </Fragment>
        );
    }
    export default EpisodeMasak;
