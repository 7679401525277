import React, { Fragment, useContext, useEffect, useState } from "react";
import globalStates from "../../../../../context/globalStates";
import useRequest from "../../../../../hooks/useRequest";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const EpisodeFive = (props) => {
  const [pickSupport, setPickSupport] = useState("");
  const [supportList, setSupportList] = useState([]);
  const [idType, setIdType] = useState("");
  const [jobPlace, setJobPlace] = useState("")
  const [webSite, setWebSite] = useState("")
  const [faxNumber, setFaxNumber] = useState("")
  const [addressVerifyType, setAddressVerifyType] = useState("");
  const { startFetch, response, loading } = useRequest();
  const [danger, setDanger] = useState({ valid: false, message: "" });
  const [ customDanger, setCustomDanger ] = useState({})
  const { innerTexts } = useContext(globalStates);

  useEffect(() => {
    if (response) {
      if (response.errNo === 0) {
        if (response.bilgiler) {
          setIdType(response.bilgiler.kimlik_turu);
          setAddressVerifyType(response.bilgiler.adres_teyit_belge_turu);
          setPickSupport(response.bilgiler.temsilci);
          setJobPlace(response.bilgiler.is_adresi);
          setWebSite(response.bilgiler.website_adresi);
          setFaxNumber(response.bilgiler.faks);
          return;
        }
        if (response.temsilciler) {
          var data = response.temsilciler;
          setSupportList(data);
        } else {
          props.handleWizard.nextStep();
        }
      }
    }
  }, [response]);

  useEffect(() => {
    startFetch("islem=temsilciler", "GET");
  }, []);
  useEffect(() => {
    startFetch("islem=yatirimci_bilgileri_getir&step=3", "GET");
  }, []);
  const saveForm = () => {
    
    var errorElement = window.jQuery("html");
    var scrollPosition = errorElement.offset().top - 25;
    window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

    if(innerTexts.pickSupport && pickSupport === "") {
      return setDanger({
        message: innerTexts.error_messages_step_one_episode,
        valid: true,
      });
    }

    if (idType === "" || idType === " ") {
      return setDanger({
        message: innerTexts.error_messages_step_one_episode,
        valid: true,
      });
    }

    if (addressVerifyType === "" || addressVerifyType === " ") {
      return setDanger({
        message: innerTexts.error_messages_step_one_episode,
        valid: true,
      });
    }

    if(jobPlace === "" || jobPlace === " ") {
      return setDanger({
        message: innerTexts.error_messages_step_one_episode,
        valid: true,
      });

    }

    var params = "";
    var model = {
      islem: "yatirimci_bilgileri_diger",
      temsilci: pickSupport,
      kimlik_turu: idType,
      adres_teyit_belge_turu: addressVerifyType,
      faks: faxNumber,
      website_adresi: webSite,
      is_adresi: jobPlace
    };
    for (var I in model) {
      params += "" + I + "=" + model[I] + "&";
    }
    setDanger({ valid: false, message: "" });
    startFetch(params, "POST");
  };

  return (
    <Fragment>
      <div style={{ textAlign: "center" }}>
        <h2> </h2>
        <div style={{ textAlign: "left" }} className="row">
          <div className="col-md-12">
            <div style={{ cursor: "pointer", width: "100%" }} className="card">
              <div style={{ cursor: "pointer" }} className="card-body">
                {
                  innerTexts.pickSupport ? (
                    <div className="col-md-12">
                    <div
                      style={{ display: "block", zIndex: 2 }}
                      className="input-group  input-group-lg lg-12"
                    >
                      <div className="input-group-prepend">
                        <label className="control-label">
                          {" "}
                          {innerTexts.label_pickup_support}
                        </label>
                      </div>
                      <div>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setPickSupport(e.target.value);
                          }}
                          value={pickSupport}
                        >
                          <option value="">Seçiniz</option>

                          {supportList &&
                              supportList.map((item, key) => (
                                <option key={key} value={item.key}>{item.value}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                    </div>
                  ) : (
                    ' '
                  )
                }

                <div className="col-md-6">
                <div
                  style={{ display: "block", zIndex: 2 }}
                    className={"input-group input-group-lg lg-6 " + (idType === ' ' && danger.valid ? 'has-error' : "")}
                >
                  <div className="input-group-prepend">
                    <label className="control-label">
                      {" "}
                      {innerTexts.label_id_type}
                    </label>
                  </div>
                  <div>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setIdType(e.target.value);
                      }}
                      onSelect={(e) => {
                        setIdType(e.target.value);
                      }}
                      value={idType}
                    >
                      <option value="">Seçiniz</option>

                      {
                        innerTexts.id === 'ISTANBULPORTFOY' ? (
                          <>
                            <option value="C">Kimlik Kartı</option>
                          </>

                        ) : (
                          <>
                            <option value="N">Nüfüs Cüzdanı</option>
                            <option value="E">Sürücü Belgesi</option>
                            <option value="P">Pasaport</option>
                            <option value="C">Kimlik Kartı</option>
                            <option value="M">Mavi Kart</option>
                          </>
                        )
                      }
                    </select>
                  </div>
                </div>
                </div>
                <div className="col-md-6">
                <div
                  style={{ display: "block", zIndex: 2 }}
                    className={"input-group  input-group-lg lg-6 " + (addressVerifyType === ' ' && danger.valid ? 'has-error' : "")}
                >
                  <div className="input-group-prepend">
                    <label className="control-label">
                      {" "}
                      {innerTexts.label_address_verify}
                    </label>
                  </div>
                  <div>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setAddressVerifyType(e.target.value);
                      }}
                      value={addressVerifyType}
                      defaultValue={addressVerifyType}
                    >
                      <option value="">Seçiniz</option>
                      {
                        innerTexts.id === 'ISTANBULPORTFOY' ? (
                          <>
                            <option value="G">E-Devlet NVI</option>
                          </>
                        ) : (
                          <>
                            <option value="F">Fatura</option>
                            <option value="I">İkametgah</option>
                            <option value="N">Nüfüs Kayıt Örneği</option>
                            <option value="E">E-Devlet</option>
                            <option value="V">Vergi Levhası</option>
                            <option value="G">E-Devlet NVI</option>
                            <option value="D">Diğer</option>
                          </>
                        )
                      }
                    </select>
                  </div>
                  </div>
                </div>


                <div className="col-md-6">
                  <div style={{ display: "block", zIndex: 1 }} className="input-group  input-group-lg lg-12">
                    <div className="input-group-prepend">
                      <label className="control-label">Faks</label>
                    </div>
                    <PhoneInput
                      placeholder="Fax Number"
                      international={true}
                      countryCallingCodeEditable={false}
                      defaultCountry="TR"
                      value={faxNumber}
                      className="form-control"
                      onChange={(e) => {
                        return setFaxNumber(e)
                      }}
                      style={{'display': 'flex'}}
                      id="faxInput"
                      required
                      maxLength={17}
                    />
                  </div>

                </div>

                <div className="col-md-6">
                  <div style={{ display: "block", zIndex: 1 }} className="input-group input-group-lg lg-12">
                    <div className="input-group-prepend">
                      <label className="control-label">Web Site</label>
                    </div>
                    <input className="form-control" onChange={(e) => {

                      setWebSite(e.target.value)

                    }} type="text" value={webSite} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div style={{ display: 'contents', zIndex: 1 }} className={"input-group input-group-lg lg-12 " + (jobPlace === '' && danger.valid ? 'has-error' : "")}>
                    <div className="input-group-prepend">
                      <label className="control-label">İş Adresi</label>
                    </div>
                    <textarea
                      onChange={(e) => {
                        setJobPlace(e.target.value.toUpperCase());
                      }}
                      value={jobPlace.toUpperCase()}
                      style={{ height: 80, width: "100%", overflow: "hidden", resize: "none" }}
                      aria-describedby="inputGroup-sizing-lg"
                      maxLength="100"
                      type="text"
                      required="required"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  {danger.valid ? (
                    <div
                      style={{ marginTop: 10 }}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {danger.message}
                    </div>
                  ) : (
                    void 0
                  )}
                </div>
                <div style={{ marginTop: 20 }} className="col-md-6">
                  <button
                    onClick={() => {
                      props.previousStep();
                    }}
                    className="btn  btn-secondr btn-lg btn-block"
                    disabled={loading}
                    style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                  >
                    {innerTexts.btn_text_back}
                  </button>
                </div>

                <div style={{ marginTop: 20 }} className="col-md-6">
                  <button
                    onClick={saveForm}
                    className="btn  btn-primary btn-lg btn-block"
                    disabled={loading}
                    style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                  >
                    {loading ? "Yükleniyor..." : innerTexts.btn_text_forward}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EpisodeFive;
