import React, { Fragment, useContext, useEffect, useState } from "react";
import globalStates from "../../../../../context/globalStates";
import useRequest from "../../../../../hooks/useRequest";
import "react-phone-number-input/style.css";

const EpisodeJobs = (props) => {


    const [titles, setTitles] = useState([])
    const { innerTexts } = useContext(globalStates);
    const [danger, setDanger] = useState({ valid: false, message: "" });
    const { startFetch, response, loading } = useRequest();
    const [companyName, setCompanyName] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [job, setJob] = useState("")
    const [devamEdiyor, setDevamEdiyor] = useState(false)
    const [companyName2, setCompanyName2] = useState("")
    const [startDate2, setStartDate2] = useState("")
    const [endDate2, setEndDate2] = useState("")
    const [job2, setJob2] = useState("")
    const [devamEdiyor2, setDevamEdiyor2] = useState(false)

    useEffect(() => {
        startFetch("islem=meslek_get", "GET");
        startFetch("islem=musteri_meslek_bilgileri&step=3", "POST");
    }, []);

    const SaveForm = () => {
        
        var errorElement = window.jQuery("html");
        var scrollPosition = errorElement.offset().top - 25;
        window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

        if (job !== "" && companyName !== "" && startDate !== "") {
            var params = ""
            var model = {
                islem: "gelir_bilgileri",
                step: 3,
                aylik_gelir: null,
                toplam_mal_varligi: "",
                meslek_tecrube_yili: null,
                mal_varlik_kaynagi: null,
                meslek: job,
                sirket_adi: companyName,
                baslangic_tarihi: startDate,
                bitis_tarihi: endDate ? endDate : "",
                meslek2: job2,
                sirket_adi2: companyName2,
                baslangic_tarihi2: startDate2,
                bitis_tarihi2: endDate2 ? endDate2 : "",
            }
            for (var I in model) {
                params += '' + I + '=' + model[I] + '&'
            }
            startFetch(params, "POST")
            props.nextStep();

        } else {
            setDanger(true)
        }
    }


    useEffect(() => {
        if (response) {
            if (response.errNo === 0) {
                if (response.meslekler) {
                    setTitles(response.meslekler)
                }
            }
        }
    }, [response])


    useEffect(() => {
        if (response) {
            if (response.errNo === 0) {
                if (response.jobs) {
                    const resjob1 = response.jobs[0];
                    if (resjob1) {
                        setCompanyName(resjob1.companyName);
                        setStartDate(resjob1.startDate !== null ? resjob1.startDate.slice(0, 10) : '');
                        setEndDate(resjob1.endDate !== null ? resjob1.endDate.slice(0, 10) : setDevamEdiyor(true));
                        setJob(resjob1.jobName);
                    }
                    const resjob2 = response.jobs[1];
                    if (resjob2) {
                        setCompanyName2(resjob2.companyName);
                        setStartDate2(resjob2.startDate !== null ? resjob2.startDate.slice(0, 10) : '');
                        setEndDate2(resjob2.endDate !== null ? resjob2.endDate.slice(0, 10) : setDevamEdiyor2(true));
                        setJob2(resjob2.jobName);
                    }
                    return;
                }
                if (response) {
                } else {
                    props.handleWizard.nextStep();
                }
            }
        }
    }, [response]);



    const handleCheckboxChange = (event) => {
        setDevamEdiyor(!devamEdiyor);
        if (!devamEdiyor) {
            setEndDate(null)
        }
    };
    const handleCheckboxChange2 = (event) => {
        setDevamEdiyor2(!devamEdiyor2)
        if (!devamEdiyor2) {
            setEndDate2(null)
        }
    };

    return (
        <Fragment>
            <div className="container">
                <h2 style={{ textAlign: "center", padding: "15px" }}>Son İki İşyeri Bilgisi</h2>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="col-md-12 pb-3 mb-3">
                            <div className="mb-3 border pb-3 rounded" >
                                <div className="col-lg-4">
                                    <div className="input-group-prepend text-center">
                                        <label htmlFor="companyName">Son İşyeri Adı</label>
                                    </div>
                                    <input
                                        type="text"
                                        id="companyName"
                                        className="form-control"
                                        value={companyName}
                                        onChange={(event) =>
                                            setCompanyName(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <div className="input-group-prepend text-center">
                                        <label htmlFor="startDate">Başlangıç Tarihi</label>
                                    </div>
                                    <input
                                        type="date"
                                        id="startDate"
                                        className="form-control"
                                        value={startDate}
                                        onChange={(event) =>
                                            setStartDate(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="col-lg-2">
                                    {!devamEdiyor ? (
                                        <>
                                            <div className="input-group-prepend text-center">
                                                <label htmlFor="endDate">Bitiş Tarihi</label>
                                            </div>
                                            <input
                                                type="date"
                                                id="endDate"
                                                className="form-control"
                                                value={endDate}
                                                onChange={(event) =>
                                                    setEndDate(event.target.value)
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <div className="input-group-prepend text-center">
                                                <label htmlFor="endDate">Bitiş Tarihi</label>
                                            </div>
                                            <input
                                                type="date"
                                                id="endDate"
                                                className="form-control"
                                                disabled
                                                placeholder="Devam Ediyor"
                                            />
                                            {/* <h5 className="text-center form-control" style={{ fontWeight: "bold" }}>Devam Ediyor</h5> */}
                                        </>
                                    )}
                                </div>
                                <div className="col-lg-3">
                                    <div className="input-group-prepend text-center">
                                        <label htmlFor="job">Meslek Adı</label>
                                    </div>
                                    <select
                                        id="job"
                                        style={{ border: danger && !job ? '1px solid #eb8787' : '', backgroundColor: danger && !job ? '#FFF8F8' : '' }}
                                        onChange={(e) => { setJob(e.target.value) }}
                                        defaultValue={job.toLowerCase()}
                                        value={job.toLowerCase()}
                                        className="form-control form-control-lg">
                                        <option value="">Seçiniz</option>
                                        {titles && titles.map((x, i) => {
                                            return (
                                                <option key={i} value={x?.Meslekler.toLowerCase()}>{x?.Meslekler}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-lg-1 text-center">
                                    <div className="input-group-prepend text-center">
                                        <label htmlFor="devamEdiyor">Devam Ediyor</label>
                                    </div>
                                    <input
                                        id="devamEdiyor"
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={devamEdiyor}
                                        onChange={(event) =>
                                            handleCheckboxChange(event)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mb-3 border pb-3 rounded" >
                                <div className="col-lg-4">
                                    <div className="input-group-prepend text-center">
                                        <label htmlFor="companyName2">Eski İşyeri Adı</label>
                                    </div>
                                    <input
                                        type="text"
                                        id="companyName2"
                                        className="form-control"
                                        value={companyName2}
                                        onChange={(event) =>
                                            setCompanyName2(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <div className="input-group-prepend text-center">
                                        <label htmlFor="startDate2">Başlangıç Tarihi</label>
                                    </div>
                                    <input
                                        type="date"
                                        id="startDate2"
                                        className="form-control"
                                        value={startDate2}
                                        onChange={(event) =>
                                            setStartDate2(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="col-lg-2">
                                    {!devamEdiyor2 ? (
                                        <>
                                            <div className="input-group-prepend text-center">
                                                <label htmlFor="endDate2">Bitiş Tarihi</label>
                                            </div>
                                            <input
                                                type="date"
                                                id="endDate2"
                                                className="form-control"
                                                value={endDate2}
                                                onChange={(event) =>
                                                    setEndDate2(event.target.value)
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <div className="input-group-prepend text-center">
                                                <label htmlFor="endDate">Bitiş Tarihi</label>
                                            </div>
                                            <h5 className="text-center form-control" style={{ fontWeight: "bold" }}>Devam Ediyor</h5>
                                        </>
                                    )}
                                </div>
                                <div className="col-lg-3">
                                    <div className="input-group-prepend text-center">
                                        <label htmlFor="job2">Meslek Adı</label>
                                    </div>
                                    <select
                                        id="job2"
                                        style={{ border: danger && !job2 ? '1px solid #eb8787' : '', backgroundColor: danger && !job2 ? '#FFF8F8' : '' }}
                                        onChange={(e) => { setJob2(e.target.value) }}
                                        defaultValue={job2.toLowerCase()}
                                        value={job2.toLowerCase()}
                                        className="form-control form-control-lg">
                                        <option value="">Seçiniz</option>
                                        {titles && titles.map((x, i) => {
                                            return (
                                                <option key={i} value={x?.Meslekler.toLowerCase()}>{x?.Meslekler}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-lg-1 text-center">
                                    <div className="input-group-prepend text-center">
                                        <label htmlFor="devamEdiyor2">Devam Ediyor</label>
                                    </div>
                                    <input
                                        disabled
                                        type="checkbox"
                                        id="devamEdiyor2"
                                        className="form-check-input"
                                        checked={devamEdiyor2}
                                        onChange={(event) =>
                                            handleCheckboxChange2(event)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 20 }} className="col-md-6">
                            <button
                                onClick={() => {
                                    if (innerTexts.id === 'TERAYATIRIM') {
                                        props.goToStep(1)
                                    } else {
                                        props.previousStep()

                                    }
                                }}
                                className="btn  btn-secondr btn-lg btn-block"
                                disabled={loading}
                                style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                            >
                                Geri
                            </button>
                        </div>
                        <div style={{ marginTop: 20 }} className="col-md-6">
                            <button
                                onClick={SaveForm}
                                className="btn  btn-primary btn-lg btn-block"
                                disabled={loading}
                                style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                            >
                                İleri
                            </button>
                        </div>
                        <div className="col-md-12">
                            {
                                danger ? <div style={{ marginTop: "25%" }} className="alert alert-danger" role="alert">
                                    Lütfen belirtilen alanları eksiksiz ve doğru bir şekilde seçiniz.
                                </div> : void 0
                            }

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default EpisodeJobs;
