
import React, { useContext } from 'react'
import globalStates from '../context/globalStates'
const Footer = () => {
	const { innerTexts } = useContext(globalStates)
  const currentYear = new Date().getFullYear();

	return (
    <footer id="footer">
      <p>{currentYear} © {innerTexts.companyFooterText}</p>
    </footer>
  );
}
export default Footer