import {URL} from "../hooks/useRequest";
import { decryptData } from "../helper/helper";

const masakParams = 'islem=masak_template&code='

export const getMasakTemplate = ({code, cookies}) => {
    let result = [];
    fetch(URL + masakParams + code, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Authorization": cookies.token
        },
    }).then(response => {
            if (response.ok) {
                return response.json()
            }
        }).then(data => {
            result = data;
            return data;
        })
    return result;
}

export async function getMasak(code, cookies, callback) {
    fetch(URL + masakParams + code, {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Authorization": cookies?.token
        },
    }).then(res => res.json())
        .then(data => {
            callback(data)
        })
}

export function getMasakGetir(cookies, callback) {
    fetch(URL + 'islem=masak_getir&tc_no=' + decryptData(cookies.TC), {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Authorization": cookies?.token
        },
    }).then(response => {
        return response?.json();

    }).then(data => {
        callback(data)
    })
}
