import React, { useState, useEffect, useContext } from "react";
import { useCookies } from "react-cookie";
import StepWizard from "react-step-wizard";
import globalStates from "../../../context/globalStates";
import Final from "./steps/Final";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import { customFetch } from "../../../hooks/customFetch";
import { decryptData } from "../../../helper/helper";

const Index = (props) => {
  const [progress, setProgress] = useState(1);
  const [cookie] = useCookies(["step", "token", "tc"]);
  const [finished, setFinished] = useState(false);
  const { innerTexts } = useContext(globalStates);
  const handleProgress = (num) => {
    var errorElement = window.jQuery("html");
    var scrollPosition = errorElement.offset().top - 25;
    window.jQuery("html, body").animate({ scrollTop: scrollPosition }, 500);

    if (progress <= num) {
      setProgress(num);
    }
  };

  const urlencoded = new URLSearchParams();
  urlencoded.append("tc_no", decryptData(cookie.TC));

  const Wizard = (props) => {
    useEffect(() => {
      customFetch({
        params: `islem=step_check`,
        method: "POST",
        contentType: "application/x-www-form-urlencoded",
        body: urlencoded,
        onSuccess: (res) => {
          var errorElement = window.jQuery("html");
          var scrollPosition = errorElement.offset().top - 25;
          window
            .jQuery("html, body")
            .animate({ scrollTop: scrollPosition }, 500);

          if (res.Step === "5") {
            setFinished(true);
          }
          if (res.Step === "0") {
            return props.goToStep(1);
          }
          console.log(res);
          if (
            innerTexts.id === "OSMANLIYATIRIM" ||
            innerTexts.id === "ISIKMENKUL" ||
            innerTexts.id === "DINAMIK"
          ) {
            if (res.Step === "1") {
              return props.goToStep(1);
            }
            if (res.Step === "2") {
              return props.goToStep(2);
            }
            if (res.Step === "3") {
              return props.goToStep(2);
            }
            if (res.Step === "4") {
              setFinished(true);
              return props.goToStep(3);
            }
            if (res.Step === "5") {
              setFinished(true);
              return props.goToStep(4);
            }
          }
          return props.goToStep(parseInt(res.Step));
        },
      });
    }, []);

    useEffect(() => {
      if (cookie.step) {
        if (
          finished &&
          (innerTexts.id === "OSMANLIYATIRIM" ||
            innerTexts.id === "ISIKMENKUL" ||
            innerTexts.id === "DINAMIK")
        ) {
          props.goToStep(4);
        } else {
          props.goToStep(5);
        }
        // else if (cookie.step > props.currentStep) {
        //     const action = () => {
        //         props.goToStep(cookie.step)
        //         handleProgress(cookie.step)
        //
        //     }
        //     action();
        // }
      }
    }, [cookie?.step, finished]);

    const handleStepClick = (num) => {
      if (num === 4 && innerTexts.id === "ALB" && finished) {
        return props.goToStep(num);
      }
      if (num === 4 && innerTexts.id === "INFOYATIRIM" && finished) {
        return props.goToStep(num);
      }
      if (num === 4 && innerTexts.id === "A1CAPITAL" && finished) {
        return props.goToStep(num);
      }
      if (num === 4 && innerTexts.id === "INTEGRALYATIRIM" && finished) {
        return props.goToStep(num);
      }
      if (num === 4 && innerTexts.id === "INVESTAZ" && finished) {
        return props.goToStep(num);
      }
      if (num === 4 && innerTexts.id === "DINAMIK" && finished) {
        return props.goToStep(num);
      }
      if (num === 4 && innerTexts.id === "GALATA" && finished) {
        return props.goToStep(num);
      }
      if (finished) {
        return;
      } else if (progress >= num) {
        props.goToStep(num);
      }
    };

    const topNavigationSelector = (companyName) => {
      switch (companyName) {
        case "OSMANLIYATIRIM":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "A1CAPITAL":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(4)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "ALNUSYATIRIM":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "TFGISTANBUL":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "MEKSA":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "GALATA":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => handleStepClick(1)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(2)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(3)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(4)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(5)}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "IKON":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => handleStepClick(1)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(2)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(3)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(4)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(5)}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "ISIKMENKUL":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "INFOYATIRIM":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(4)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "TERAYATIRIM":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "PHILIPCAPITAL":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "OPTIMUS":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "OPTIMUS1":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "ISTANBULPORTFOY":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "INVESTAZ":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(4)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "STRATEJI":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "INTEGRALYATIRIM":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(4)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );

        case "NOORCM":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );
        case "DINAMIK":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              {/* <li onClick={() => { return finished ? null : handleStepClick(2) }} className={finished ? 'disabled' : props.currentStep === 2 ? "active" : progress >= 2 ? "none" : "disabled"}><a href="#step-2">
                                <h4 className="list-group-item-heading">2</h4>
                                <p className="list-group-item-text">{innerTexts.yerindelik ? innerTexts.label_step_two_yerindelik : innerTexts.label_step_two}</p>
                            </a></li> */}
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => handleStepClick(3)}
                className={
                  finished
                    ? ""
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );
        case "ALB":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return handleStepClick(4);
                }}
                className={
                  finished
                    ? ""
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );
        case "AHLATCI":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(4);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );
        case "ACAR":
          return (
            <ul className="nav nav-pills nav-justified thumbnail setup-panel">
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(1);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 1
                    ? "active"
                    : progress >= 1
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-1">
                  <h4 className="list-group-item-heading">1</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_one}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(2);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 2
                    ? "active"
                    : progress >= 2
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-2">
                  <h4 className="list-group-item-heading">2</h4>
                  <p className="list-group-item-text">
                    {innerTexts.yerindelik
                      ? innerTexts.label_step_two_yerindelik
                      : innerTexts.label_step_two}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(3);
                }}
                className={
                  finished
                    ? "disabled"
                    : props.currentStep === 3
                    ? "active"
                    : progress >= 3
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-3">
                  <h4 className="list-group-item-heading">3</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_three}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return handleStepClick(4);
                }}
                className={
                  finished
                    ? ""
                    : props.currentStep === 4
                    ? "active"
                    : progress >= 4
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">4</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_four}
                  </p>
                </a>
              </li>
              <li
                onClick={() => {
                  return finished ? null : handleStepClick(5);
                }}
                className={
                  props.currentStep === 5
                    ? "active"
                    : progress >= 5
                    ? "none"
                    : "disabled"
                }
              >
                <a href="#step-4">
                  <h4 className="list-group-item-heading">5</h4>
                  <p className="list-group-item-text">
                    {innerTexts.label_step_five}
                  </p>
                </a>
              </li>
            </ul>
          );
      }
    };

    return (
      <div className="container">
        <div style={{ textAlign: "center" }}></div>
        <div className="row form-group">
          <div className="col-xs-12">
            {topNavigationSelector(process.env.REACT_APP_BUILDTYPE)}
          </div>
        </div>
      </div>
    );
  };

  const stepSelector = (companyName) => {
    switch (companyName) {
      case "OSMANLIYATIRIM":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              {/*<Step2 handleProgress={handleProgress} />*/}
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final handleProgress={handleProgress} />
            </StepWizard>
          </>
        );

      case "ALNUSYATIRIM":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "A1CAPITAL":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "ISIKMENKUL":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              {/*<Step2 handleProgress={handleProgress} />*/}
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final handleProgress={handleProgress} />
            </StepWizard>
          </>
        );

      case "INFOYATIRIM":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "TERAYATIRIM":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "PHILIPCAPITAL":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "TFGISTANBUL":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "MEKSA":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "GALATA":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "IKON":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "OPTIMUS":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "OPTIMUS1":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "ISTANBULPORTFOY":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "INVESTAZ":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "STRATEJI":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "INTEGRALYATIRIM":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );

      case "NOORCM":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );
      case "DINAMIK":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              {/* <Step2 handleProgress={handleProgress} /> */}
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );
      case "ALB":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} finished={finished} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );
      case "AHLATCI":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );
      case "ACAR":
        return (
          <>
            <StepWizard nav={<Wizard />}>
              <Step1 handleProgress={handleProgress} />
              <Step2 handleProgress={handleProgress} />
              <Step3 handleProgress={handleProgress} />
              <Step4 handleProgress={handleProgress} />
              <Final
                setFinished={setFinished}
                handleProgress={handleProgress}
              />
            </StepWizard>
          </>
        );
    }
  };

  return (
    <div className="container">
      <div style={{ paddingTop: 45, paddingBottom: 30 }}>
        {stepSelector(process.env.REACT_APP_BUILDTYPE)}
      </div>
    </div>
  );
};

export default Index;
