if (process.env.REACT_APP_BUILDTYPE === "A1CAPITAL") {
    require('./css/a1CapitalStyle.css')
}
else if (process.env.REACT_APP_BUILDTYPE == "ISIKMENKUL") {
    require('./css/isikMenkulStyle.css')
}
else if (process.env.REACT_APP_BUILDTYPE == "OPTIMUS") {
    require('./css/isikMenkulStyle.css')
}
else if (process.env.REACT_APP_BUILDTYPE == "OPTIMUS1") {
    require('./css/isikMenkulStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "TERAYATIRIM") {
    require('./css/teraYatirimStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "PHILIPCAPITAL") {
    require('./css/philipCapitalStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "INFOYATIRIM") {
    require('./css/infoYatirimStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "OSMANLIYATIRIM") {
    require('./css/infoYatirimStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "ISTANBULPORTFOY") {
    require('./css/istanbulPortfoy.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "ALNUSYATIRIM") {
    require('./css/alnusYatirim.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "INVESTAZ") {
    require('./css/investAz.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "STRATEJI") {
    require('./css/stratejiStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "INTEGRALYATIRIM") {
    require('./css/integralStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "NOORCM") {
    require('./css/noorCmStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "DINAMIK") {
    require('./css/dinamikStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "ALB") {
    require('./css/dinamikStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "AHLACI") {
    require('./css/dinamikStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "GALATA") {
    require('./css/galataStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "IKON") {
    require('./css/ikonStyle.css')
}
else if(process.env.REACT_APP_BUILDTYPE == "ACAR") {
    require('./css/acarStyle.css')
}
