import React, { Fragment, useContext, useEffect, useState } from 'react'
import globalStates from '../../../../../context/globalStates';
import useRequest from '../../../../../hooks/useRequest'
import List from "../../../../../sources/variables/companies.json";

const toPrefixFromIban = (arg) => {
  try {
    const replace = arg
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    return replace;
  } catch (e) {
    return "";
  }
};
const EpisodeFour = (props) => {
    const [iban, setIban] = useState("TR");
    const [fullname, setFullname] = useState("")
    const { startFetch, response, loading } = useRequest()
    const [danger, setDanger] = useState({ valid: false, message: "" })
    const { innerTexts } = useContext(globalStates)
    const [virmanCheck, setVirmanCheck] = useState(false)
    const [urlClickedCount, setUrlClickedCount] = useState(0)

    useEffect(() => {
        if (response) {
            if (response.errNo === 0) {
                if (response.bilgiler) {
                    var data = response.bilgiler
                    if (data.iban) {
                        setIban(toPrefixFromIban(data.iban) || "")
                    }
                    if (data.ad) {
                      setFullname("" + data.ad + " " + data.soyad)
                    }
                } else {
                  if (innerTexts.portfoy) {
                    return props.nextStep()
                  } else if (innerTexts.branch) {
                    return props.nextStep()
                  }
                  props.handleWizard.nextStep()
                }
            }
        }
    }, [response])

    useEffect(() => {

        startFetch("islem=yatirimci_bilgileri_getir&step=3", "GET")

    }, [])
    useEffect(() => {

      startFetch("islem=kimlik_bilgileri_getir&step=1", "GET")
    }, [])
    const saveForm = () => {
      
      var errorElement = window.jQuery("html");
      var scrollPosition = errorElement.offset().top - 25;
      window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

        // to ignore the branch selection step
        if(innerTexts.id === 'INFOYATIRIM') {
          props.handleWizard.nextStep()
          return
        }

        if (!innerTexts.companyIbanRequired) {
          if (innerTexts.id === 'DINAMIK' && !virmanCheck) {
            setDanger({ message: 'Genel Virman Transfer Talimatını okuyup onaylayınız!', valid: true })
            return
          }
          if (iban.length === 32) {
          var params = ""
          var model = {
            islem: "banka_hesap_bilgileri",
            iban: "TR" + iban.replace("TR", "").replace(/\s/g, "")
          }
          for (var I in model) {
            params += '' + I + '=' + model[I] + '&'
          }
          startFetch(params, "POST", null, null, (data) => {
            if (data.errNo === -16) {
              setDanger({ message: 'Girmiş olduğunuz Banka Iban bilgisini kontrol eder misiniz', valid: true })
            }
          })
        }
        else if (iban === 'TR') {
          var params = ""
          var model = {
            islem: "banka_hesap_bilgileri",
          }
          for (var I in model) {
            params += '' + I + '=' + model[I] + '&'
          }
          startFetch(params, "POST", null, null, (data) => {
            if (data.errNo === -16) {
              setDanger({ message: 'Girmiş olduğunuz Banka Iban bilgisini kontrol eder misiniz', valid: true })
            }
          })
        }
        else {
          setDanger({ valid: true, message: innerTexts.error_messages_wrong_iban })
        }
      }
 
      if (innerTexts.companyIbanRequired) {
        if (innerTexts.id === 'DINAMIK' && !virmanCheck) {
          setDanger({ message: 'Genel Virman Transfer Talimatını okuyup onaylayınız!', valid: true })
          return
        }
            if (iban !== "" && iban.length === 32) {
                var params = ""
                var model = {
                    islem: "banka_hesap_bilgileri",
                    iban: "TR" + iban.replace("TR", "").replace(/\s/g, "")
                }
                for (var I in model) {
                    params += '' + I + '=' + model[I] + '&'
                }
                setDanger({ valid: false, message: "" })
                return startFetch(params, "POST", null, null, (data) => {
                  if(data.errNo === -16) {
                    setDanger({ message: 'Girmiş olduğunuz Banka Iban bilgisini kontrol eder misiniz', valid: true })
                  }
                })
            }
            return setDanger({ message: innerTexts.error_messages_wrong_iban, valid: true })
        }
        return
    }

    return (
      <Fragment>
        <div style={{ textAlign: "center" }}>
          <h2> </h2>
          <div style={{ textAlign: "left" }} className="row">
            <div className="col-md-12">
              <div
                style={{ cursor: "pointer", width: "100%" }}
                className="card"
              >
                <div style={{ cursor: "pointer" }} className="card-body">
                  <div key={0}
                    style={{ display: "block", zIndex: 2 }}
                    className="input-group  input-group-lg lg-12"
                  >
                    <div className="input-group-prepend">
                      <label className="control-label">
                        {" "}
                        {innerTexts.label_account_name}
                      </label>
                    </div>
                    <input
                      disabled
                      type="text"
                      value={fullname}
                      onChange={(e) => {
                        if (e.target.value.length <= 32)
                          setIban(toPrefixFromIban(e.target.value));
                      }}
                      required="required"
                      className="form-control"
                    />
                  </div>
                  <div
                    style={{ display: "block", zIndex: 2 }}
                    className="input-group  input-group-lg lg-12"
                  >
                    <div className="input-group-prepend">
                      {innerTexts.companyIbanRequired ? (
                        <label className="control-label">
                          {innerTexts.label_iban_required}
                        </label>
                      ) : (
                          <label key={1} className="control-label">
                          {innerTexts.label_iban_not_required}
                        </label>
                      )}
                    </div>
                    <input
                      value={iban}
                      onChange={(e) => {
                        if (e.target.value.length >= 2 && e.target.value.length <= 32)
                          setIban( toPrefixFromIban('TR' + e.target.value.replace('TR', '')));
                      }}
                      onPaste={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        const clipboardData = e.clipboardData || window.clipboardData;
                        const pastedData = clipboardData.getData("Text");
                        if (pastedData.length >= 2 && pastedData.length <= 32)
                            setIban( toPrefixFromIban('TR' + pastedData.replace('TR', '')));
                      }
                      }
                      placeholder="TR"
                      type="text"
                      required="required"
                      className="form-control"
                    />
                  </div>
                  {
                    innerTexts.id === 'DINAMIK' && 
                    <div
                    style={{ marginTop: "15px" }}
                    >
                        <input
                                onClick={(e) => {
                                    if (innerTexts.id === "DINAMIK") {
                                            window.open(innerTexts.label_privacy_url_2, "_blank");
                                            setUrlClickedCount(1);
                                            setVirmanCheck(true);
                                            setDanger({ valid: false, message: "" })
                                            if (urlClickedCount === 1) {
                                                setDanger({ valid: false, message: "" })
                                                e.preventDefault();
                                        }
                                    }
                                }}
                                defaultChecked={virmanCheck}
                                checked={virmanCheck}
                                style={{ marginRight: 5}}
                                className="form-check-input"
                                type="checkbox"
                                id="agreementCheckbox"
                            />
                            <label className="form-check-label" 
                            style={{
                                color: "black",
                                fontSize: 14,
                                fontWeight: "400",
                                marginLeft: 10
                            }} 
                            // htmlFor={item.value.trim()}
                            > 
                            <a href='/static/pdf/genelVirman.pdf' onClick={()=> {setUrlClickedCount(1); setVirmanCheck(true); setDanger({ valid: false, message: "" })} } target='_blank'>Genel Virman Transfer Talimatı bilgilendirme metnini</a> okuduğumu onaylıyorum.
                            </label>

                    </div>
                  }
                  <div className="col-md-12">
                    <div
                      style={{ marginTop: 10 }}
                      className="alert alert-info"
                      role="alert"
                    >
                      {innerTexts.label_iban_warning}
                    </div>
                  </div>
                  <div className="col-md-12">
                    {danger.valid ? (
                      <div
                        style={{ marginTop: 10 }}
                        className="alert alert-danger"
                        role="alert"
                      >
                        {danger.message}
                      </div>
                    ) : (
                      void 0
                    )}
                  </div>
                  <div style={{ marginTop: 20 }} className="col-md-6">
                    <button
                      onClick={() => {
                        props.previousStep();
                      }}
                      className="btn  btn-secondr btn-lg btn-block"
                      disabled={loading}
                      style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                    >
                      {innerTexts.btn_text_back}
                    </button>
                  </div>

                  <div style={{ marginTop: 20 }} className="col-md-6">
                    <button
                      onClick={saveForm}
                      className="btn  btn-primary btn-lg btn-block"
                      disabled={loading}
                      style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                    >
                      {loading ? "Yükleniyor..." : innerTexts.btn_text_forward}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
}
export default EpisodeFour;
