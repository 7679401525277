import React, { useContext, useEffect } from 'react'
import globalStates from '../../../../context/globalStates'
import { customFetch } from "../../../../hooks/customFetch";
import { useCookies } from "react-cookie";
import { decryptData } from '../../../../helper/helper';


const Final = (props) => {
  const { innerTexts } = useContext(globalStates)
  const [cookie] = useCookies(["tc"])

  const urlencoded = new URLSearchParams();
  urlencoded.append("tc_no", decryptData(cookie.TC));

  useEffect(() => {


    if (props.currentStep === 5) {
      customFetch({
        params: `islem=step_check`,
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        body: urlencoded,
        onSuccess: (res) => {
          if (res.Step === '5') {
            props.setFinished(true)
          }
        }
      })
    }
  }, [props.currentStep])

  if ((innerTexts.id === 'OSMANLIYATIRIM' || innerTexts.id === 'ISIKMENKUL' || innerTexts.id === 'DINAMIK') && props.currentStep === 4) {
    return (
      <div className="container">
        <div className="row">
          <div
            className="col-md-12"
            style={{ textAlign: "center", marginBottom: 20 }}
          >
            <div className="alert alert-success" role="alert">
              <h2 className="alert-heading">{innerTexts.label_step_final_title}</h2>
              <h4>{innerTexts.label_step_final_sub_title}</h4>
              <hr />
              <p className="mb-0" dangerouslySetInnerHTML={{ __html: innerTexts.label_step_final_description }}></p>
            </div>
            <button
              onClick={() => {
                window.location.replace("" + innerTexts.companyHomePage);
              }}
              className="btn btn-primary btn-block"
            >
              Anasayfa'ya Git
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!innerTexts.customSteps && props.currentStep === 5) {
    return (
      <div className="container">
        <div className="row">
          <div
            className="col-md-12"
            style={{ textAlign: "center", marginBottom: 20 }}
          >
            <div className="alert alert-success" role="alert">
              <h2 className="alert-heading">{innerTexts.label_step_final_title}</h2>
              <h4>{innerTexts.label_step_final_sub_title}</h4>
              <hr />
              <p className="mb-0">
                {innerTexts.label_step_final_description}
              </p>
            </div>
            <button
              onClick={() => {
                window.location.replace("" + innerTexts.companyHomePage);
              }}
              className="btn btn-primary btn-block"
            >
              Anasayfa'ya Git
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (<div></div>)
  }
}


export default Final
